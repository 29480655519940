import { Keyword } from "common/types.ts";
import KeywordsTable from "@/src/pages/home/KeywordsModal/KeywordsTable.tsx";

export default function ViewKeywords({
  keywords,
  closeModal,
}: {
  keywords: Keyword[];
  closeModal: () => void;
}) {
  return (
    <div className="flex flex-col">
      <KeywordsTable keywords={keywords} closeModal={closeModal} />
    </div>
  );
}
