import PlacesSearchBox from "@/src/common/components/AddBusinessModal/PlacesSearchBox.tsx";
import usePlaceMutations from "@/src/common/datahooks/usePlaceMutations.tsx";
import { BasePlace, Coordinates } from "common/types.ts";
import { HTTPError } from "@/src/common/helpers/HTTP.ts";
import { useEffect, useState } from "react";
import { useAppStore } from "common/hooks/useAppStore.ts";
import { Badge, Button, RatingStars, Checkbox } from "@/src/common/components";

import LocationDot from "fontawesome/solid/location-dot.svg?react";
import CircleExclamation from "fontawesome/solid/circle-exclamation.svg?react";

export default function PlaceSelection({
  selectedPlace,
  setSelectedPlace,
  closeDialog,
}: {
  selectedPlace: BasePlace | null;
  setSelectedPlace: (place: BasePlace | null) => void;
  closeDialog: () => void;
}) {
  const [location, setLocation] = useState<Coordinates | undefined>();
  const geoState = useAppStore((state) => state.geoState);
  const [isDuplicateError, setIsDuplicateError] = useState(false);
  const { addPlace } = usePlaceMutations();

  function updateLocation() {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        useAppStore.getState().setGeoState("granted");
        setLocation({
          longitude: location.coords.longitude,
          latitude: location.coords.latitude,
        });
      },
      (positionError) => {
        if (positionError.PERMISSION_DENIED) {
          useAppStore.getState().setGeoState("denied");
        }
      },
    );
  }

  useEffect(() => {
    if (geoState === "granted") {
      updateLocation();
    }
  }, []);

  function onConfirm() {
    if (selectedPlace) {
      addPlace({
        placeName: selectedPlace.name,
        placeId: selectedPlace.id,
        customerId: "AzZWzRUSEDjfb581U",
      }).catch((error) => {
        if (error instanceof HTTPError && error.code === 409) {
          setIsDuplicateError(true);
        }
      });
    }
  }
  return selectedPlace ? (
    <>
      <div className="flex flex-col rounded-5xl border border-grey-300 p-6">
        <div className="mb-1 flex gap-x-0.5">
          <span className="text-base font-semibold">
            {selectedPlace.rating}
          </span>
          <RatingStars rating={selectedPlace.rating} />
          <span className="text-base text-grey-500">
            ({selectedPlace.rating_count})
          </span>
        </div>
        <span className="mb-2 text-2xl font-semibold">
          {selectedPlace.name}
        </span>
        <div className="flex items-center justify-between gap-x-3">
          <Badge size="md" variant="grey">
            <LocationDot />
            {selectedPlace.address}
          </Badge>
          <Button
            variant="stroke"
            size="sm"
            onPress={() => {
              setSelectedPlace(null);
              setIsDuplicateError(false);
            }}
          >
            This isn’t me
          </Button>
        </div>
      </div>
      {isDuplicateError && (
        <div className="mt-2 flex gap-x-1.5">
          <CircleExclamation className="mt-0.5 size-4 fill-red-400" />
          <span className="text-sm text-red-400">
            This GBP is already registered. Please check if you selected the
            right one, or contact support in case there is an issue
          </span>
        </div>
      )}
      <div className="mt-6 flex w-full flex-col-reverse gap-4 lg:flex-row">
        <Button
          variant="white"
          size="md"
          className="flex-1"
          onPress={closeDialog}
          isDisabled={isDuplicateError}
        >
          Cancel
        </Button>
        <Button
          variant="black"
          size="md"
          className="flex-1"
          onPress={onConfirm}
          isDisabled={isDuplicateError}
        >
          Confirm
        </Button>
      </div>
    </>
  ) : (
    <>
      <PlacesSearchBox
        selectPlace={(place) => setSelectedPlace(place)}
        location={location}
      />
      {navigator.geolocation && geoState !== "granted" && (
        <Checkbox
          className="mt-2"
          onChange={(isSelected) => {
            console.log(isSelected);
          }}
        >
          Enable location services in your browser for more accurate search
          results
        </Checkbox>
      )}
      <Button
        variant="white"
        size="md"
        className="mr-auto mt-6"
        onPress={closeDialog}
      >
        Go back
      </Button>
    </>
  );
}
