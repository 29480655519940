import { useQuery } from "@tanstack/react-query";
import { GridPoint, Keyword, RankedPlace } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";
import { getKeywordDetails, getDummyPlace } from "@/src/dummyGenerator.ts";

async function getKeywordDetailsRequest(
  placeId: string,
  keywordId: string,
  date: string | undefined,
): Promise<{
  grid: GridPoint[];
  places: RankedPlace[];
}> {
  if (getDummyPlace(placeId)) {
    return getKeywordDetails(placeId, keywordId, date);
  }
  const { grid, places } = await get(
    `v1/places/${placeId}/keywords/${keywordId}?date=${date}`,
  );
  return { grid, places };
}
export default function useKeywordDetails(
  placeId: string,
  keyword: Keyword | undefined,
  date: string | undefined,
) {
  const keywordId = keyword ? keyword.id : "";
  const {
    data: keywordDetails,
    isLoading: isLoadingKeywordDetails,
    error: keywordDetailsError,
    refetch: refetchKeywordDetails,
  } = useQuery({
    queryKey: ["keywordDetails", placeId, keywordId, date],
    queryFn: () => getKeywordDetailsRequest(placeId, keywordId, date),
    //when failed grids are called they are auto generated
    enabled:
      !!date &&
      !!keyword &&
      (keyword.grid_state === "ready" || keyword.grid_state === "failed"),
  });

  return {
    keywordDetails,
    isLoadingKeywordDetails,
    keywordDetailsError,
    refetchKeywordDetails,
  };
}
