import useKeywordsMutations from "@/src/common/datahooks/useKeywordsMutations.tsx";
import { Button } from "common/components";

export default function AddKeyword({
  keywordLabel,
  isLimitReached,
}: {
  keywordLabel: string;
  isLimitReached: boolean;
}) {
  const { addKeyword, isAddingKeyword } = useKeywordsMutations();

  function onAdd() {
    addKeyword({ label: keywordLabel });
  }
  if (isLimitReached) {
    return (
      <Button size="sm" variant="purple">
        Upgrade
      </Button>
    );
  }
  return (
    <Button
      size="sm"
      variant="green"
      isDisabled={isAddingKeyword}
      onPress={onAdd}
    >
      Add
    </Button>
  );
}
