import { getRankColorClassnames, getRankLabel } from "common/utils.ts";
import { type GridPoint as GridPointType } from "common/types.ts";
import { clsx } from "clsx";

import Bolt from "fontawesome/sharp-solid/bolt.svg?react";
import Star from "fontawesome/solid/star.svg?react";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import mapboxgl, { Marker } from "mapbox-gl";
import { useSelectedPlace } from "@/src/contexts.ts";
import GridTooltip from "@/src/pages/home/GridMap/GridTooltip.tsx";

interface GridPointProps {
  point: GridPointType;
  map: mapboxgl.Map;
  onClick: () => void;
  isSelected: boolean | undefined;
}
export default function GridPoint({
  point,
  map,
  onClick,
  isSelected,
}: GridPointProps) {
  const { selectedPlace } = useSelectedPlace();
  const timeoutRef = useRef<number>();
  const { latitude, longitude, places, upgrade, rank } = point;
  const { bg, bgHover } = getRankColorClassnames(rank);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isCenterPoint =
    latitude === selectedPlace.location.latitude &&
    longitude === selectedPlace.location.longitude;

  const marker: Marker = useMemo(() => {
    const mk = new mapboxgl.Marker({
      element: document.createElement("button"),
      className: "rounded-full",
    });
    mk.setLngLat({
      lng: longitude,
      lat: latitude,
    });

    return mk;
  }, []);

  useEffect(() => {
    if (onClick) {
      marker.getElement().addEventListener("click", onClick);
      return () => marker.getElement().removeEventListener("click", onClick);
    }
  }, [onClick]);

  useEffect(() => {
    marker.addTo(map);
    return () => {
      marker.remove();
    };
  }, []);

  useEffect(() => {
    if (isSelected) {
      setIsTooltipOpen(false);
    } else {
      const onMouseEnter = () => {
        timeoutRef.current = window.setTimeout(
          () => setIsTooltipOpen(true),
          300,
        );
      };
      const onMouseLeave = () => {
        clearTimeout(timeoutRef.current);
        setIsTooltipOpen(false);
      };
      marker.getElement().addEventListener("mouseenter", onMouseEnter);
      marker.getElement().addEventListener("mouseleave", onMouseLeave);

      return () => {
        marker.getElement().removeEventListener("mouseenter", onMouseEnter);
        marker.getElement().removeEventListener("mouseleave", onMouseLeave);
      };
    }
  }, [isSelected]);

  let child = isCenterPoint ? (
    <Star className="size-4 lg:size-5" />
  ) : (
    <span className="text-sm font-medium text-grey-900 lg:text-lg">
      {getRankLabel(rank)}
    </span>
  );
  if (upgrade) {
    child = (
      <Bolt className="size-4 fill-purple-500 transition-[fill] group-hover:fill-white lg:size-5" />
    );
  }
  const finalClassName = clsx(
    "group lg:size-12 size-7 flex items-center justify-center rounded-full cursor-pointer transition-[opacity,background-color]",
    upgrade
      ? "border border-dashed border-purple-500 bg-purple-500/20 backdrop-blur hover:bg-purple-500"
      : [bg, bgHover],
    isSelected !== undefined &&
      (isSelected ? "border-grey-900 border-2" : "opacity-65"),
  );

  return (
    <>
      {createPortal(
        <div className={finalClassName}>{child}</div>,
        marker.getElement(),
      )}
      <GridTooltip
        isOpen={isTooltipOpen}
        text={
          upgrade &&
          `See the full impact of ${selectedPlace.name} ranking by upgrading to the ${upgrade}`
        }
        places={places && places}
        location={{ latitude, longitude }}
        map={map}
      />
    </>
  );
}
