import { forwardRef, HTMLAttributes, ReactNode } from "react";
import { clsx } from "clsx";

export type badgeVariants =
  | "grey"
  | "green"
  | "greenFull"
  | "purple"
  | "purpleFull"
  | "red"
  | "stroke"
  | "yellow"
  | "white";
type badgeSizes = "md" | "sm";

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  variant: badgeVariants;
  size: badgeSizes;
  children: ReactNode;
}

const variantStyles: Record<badgeVariants, string> = {
  stroke: "border border-grey-300",
  grey: "bg-grey-200 text-grey-900",
  green: "text-green-800 bg-green-100 [&_svg]:fill-green-800",
  greenFull: "bg-green-500 text-grey-900 bg-green-100 [&_svg]:fill-grey-900",
  purple: "text-purple-500 bg-purple-500/20 [&_svg]:fill-purple-500",
  purpleFull: "bg-purple-500 text-white [&_svg]:fill-white",
  red: "bg-red-100 text-red-600 [&_svg]:fill-red-600",
  yellow: "bg-yellow-100 text-yellow-500 [&_svg]:fill-yellow-500",
  white: "bg-white text-grey-900 [&_svg]:fill-grey-900",
};

const sizeStyles: Record<badgeSizes, string> = {
  sm: "h-6 rounded-lg px-2.5 [&_svg]:size-3 [&_svg]:-mx-0.5",
  md: "h-7 rounded-xl px-3 [&_svg]:size-3 [&_svg]:-mx-0.5",
};

const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  ({ children, className, variant, size, ...rest }, ref) => {
    return (
      <div
        {...rest}
        ref={ref}
        className={clsx(
          "flex items-center gap-x-1.5 text-xs font-medium",
          variantStyles[variant],
          sizeStyles[size],
          className,
        )}
      >
        {children}
      </div>
    );
  },
);
export { Badge };
