import { clsx } from "clsx";
import { getNumberFromString } from "common/utils.ts";
const shapes = [
  <g filter="url(#avatar-svg-filter)">
    <path
      d="M14.0002 0.909912C17.6149 0.909912 20.5452 3.8402 20.5452 7.45491C20.5452 11.0696 17.6149 13.9999 14.0002 13.9999C10.3855 13.9999 7.45516 11.0696 7.45516 7.45491C7.45516 3.8402 10.3855 0.909912 14.0002 0.909912Z"
      fill="url(#avatar-gradient)"
    />
    <path
      d="M14.0002 0.909912C17.6149 0.909912 20.5452 3.8402 20.5452 7.45491C20.5452 11.0696 17.6149 13.9999 14.0002 13.9999C10.3855 13.9999 7.45516 11.0696 7.45516 7.45491C7.45516 3.8402 10.3855 0.909912 14.0002 0.909912Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M14.0002 13.9999C21.2296 13.9999 27.0902 19.8605 27.0902 27.0899H0.910156C0.910156 19.8605 6.77075 13.9999 14.0002 13.9999Z"
      fill="url(#avatar-gradient)"
    />
    <path
      d="M14.0002 13.9999C21.2296 13.9999 27.0902 19.8605 27.0902 27.0899H0.910156C0.910156 19.8605 6.77075 13.9999 14.0002 13.9999Z"
      fill="white"
      fillOpacity="0.1"
    />
  </g>,
  <g filter="url(#avatar-svg-filter)">
    <path
      d="M7.45516 7.45491C7.45516 3.8402 10.3855 0.909912 14.0002 0.909912C17.6149 0.909912 20.5452 3.8402 20.5452 7.45491C20.5452 11.0696 17.6149 13.9999 14.0002 13.9999C10.3855 13.9999 7.45516 11.0696 7.45516 7.45491Z"
      fill="url(#avatar-gradient)"
    />
    <path
      d="M7.45516 7.45491C7.45516 3.8402 10.3855 0.909912 14.0002 0.909912C17.6149 0.909912 20.5452 3.8402 20.5452 7.45491C20.5452 11.0696 17.6149 13.9999 14.0002 13.9999C10.3855 13.9999 7.45516 11.0696 7.45516 7.45491Z"
      fill="white"
      fillOpacity="0.1"
    />
    <path
      d="M14.0002 27.0899H27.0902C27.0902 19.8605 21.2296 13.9999 14.0002 13.9999H0.910156C0.910156 21.2293 6.77075 27.0899 14.0002 27.0899Z"
      fill="url(#avatar-gradient)"
    />
    <path
      d="M14.0002 27.0899H27.0902C27.0902 19.8605 21.2296 13.9999 14.0002 13.9999H0.910156C0.910156 21.2293 6.77075 27.0899 14.0002 27.0899Z"
      fill="white"
      fillOpacity="0.1"
    />
  </g>,
  <g filter="url(#avatar-svg-filter)">
    <path
      d="M20.5452 7.45491C20.5452 3.8402 17.6149 0.909912 14.0002 0.909912C10.3855 0.909912 7.45516 3.8402 7.45516 7.45491C7.45516 11.0696 10.3855 13.9999 14.0002 13.9999C17.6149 13.9999 20.5452 11.0696 20.5452 7.45491Z"
      fill="url(#avatar-gradient)"
    />
    <path
      d="M14.0002 27.0899H0.910156C0.910156 19.8605 6.77075 13.9999 14.0002 13.9999H27.0902C27.0902 21.2293 21.2296 27.0899 14.0002 27.0899Z"
      fill="url(#avatar-gradient)"
    />
  </g>,
  <g filter="url(#avatar-svg-filter)">
    <path
      d="M14.0002 0.909912C10.3855 0.909912 7.45516 3.8402 7.45516 7.45491C7.45516 11.0696 10.3855 13.9999 14.0002 13.9999H0.910156C0.910156 21.2293 6.77075 27.0899 14.0002 27.0899C21.2296 27.0899 27.0902 21.2293 27.0902 13.9999H14.0002C17.6149 13.9999 20.5452 11.0696 20.5452 7.45491C20.5452 3.8402 17.6149 0.909912 14.0002 0.909912Z"
      fill="url(#avatar-gradient)"
    />
  </g>,
  <g filter="url(#avatar-svg-filter)">
    <path
      d="M14.0002 0.910156C10.3855 0.910156 7.45516 3.84044 7.45516 7.45516C7.45516 11.0699 10.3855 14.0002 14.0002 14.0002H0.910156V27.0902H27.0902V14.0002H14.0002C17.6149 14.0002 20.5452 11.0699 20.5452 7.45516C20.5452 3.84044 17.6149 0.910156 14.0002 0.910156Z"
      fill="url(#avatar-gradient)"
    />
  </g>,
  <g filter="url(#avatar-svg-filter)">
    <path
      d="M14.0002 0.909912C10.3855 0.909912 7.45516 3.84021 7.45516 7.45491C7.45516 11.0696 10.3855 13.9999 14.0002 13.9999H0.910156V27.0899H27.0902C27.0902 19.8605 21.2296 13.9999 14.0002 13.9999C17.6149 13.9999 20.5452 11.0696 20.5452 7.45491C20.5452 3.84021 17.6149 0.909912 14.0002 0.909912Z"
      fill="url(#avatar-gradient)"
    />
    <path
      d="M14.0002 0.909912C10.3855 0.909912 7.45516 3.84021 7.45516 7.45491C7.45516 11.0696 10.3855 13.9999 14.0002 13.9999H0.910156V27.0899H27.0902C27.0902 19.8605 21.2296 13.9999 14.0002 13.9999C17.6149 13.9999 20.5452 11.0696 20.5452 7.45491C20.5452 3.84021 17.6149 0.909912 14.0002 0.909912Z"
      fill="white"
      fillOpacity="0.1"
    />
  </g>,
  <g filter="url(#avatar-svg-filter)">
    <path
      d="M14.0002 0.909912C10.3855 0.909912 7.45516 3.84021 7.45516 7.45491C7.45516 11.0696 10.3855 13.9999 14.0002 13.9999H7.45516C3.84045 13.9999 0.910156 16.9302 0.910156 20.5449C0.910156 24.1596 3.84045 27.0899 7.45516 27.0899H20.5452C24.1599 27.0899 27.0902 24.1596 27.0902 20.5449C27.0902 16.9302 24.1599 13.9999 20.5452 13.9999H14.0002C17.6149 13.9999 20.5452 11.0696 20.5452 7.45491C20.5452 3.84021 17.6149 0.909912 14.0002 0.909912Z"
      fill="url(#avatar-gradient)"
    />
  </g>,
];
const colors = [
  {
    backgroundClassName: "bg-[linear-gradient(180deg,#B1A1FD_0%,#5221B5_100%)]",
    svgGradient: (
      <linearGradient
        id="avatar-gradient"
        x1="14.0002"
        y1="0.909912"
        x2="14.0002"
        y2="27.0899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E3E0FF" />
        <stop offset="1" stopColor="#9675FA" stopOpacity="0.71" />
      </linearGradient>
    ),
  },
  {
    backgroundClassName:
      "bg-[linear-gradient(180deg,#FF9E9F_0%,_#9F1413_100%)]",
    svgGradient: (
      <linearGradient
        id="avatar-gradient"
        x1="14.0002"
        y1="0.909912"
        x2="14.0002"
        y2="27.0899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FDE7E7" />
        <stop offset="1" stopColor="#FF5757" stopOpacity="0.7" />
      </linearGradient>
    ),
  },
  {
    backgroundClassName:
      "bg-[linear-gradient(180deg,#FCCA00_0%,_#A15800_100%)]",
    svgGradient: (
      <linearGradient
        id="avatar-gradient"
        x1="14.0002"
        y1="0.909912"
        x2="14.0002"
        y2="27.0899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEFEDE" />
        <stop offset="1" stopColor="#FCCA00" stopOpacity="0.7" />
      </linearGradient>
    ),
  },
  {
    backgroundClassName:
      "bg-[linear-gradient(180deg,#5BE769_0%,_#2B823A_100%)]",
    svgGradient: (
      <linearGradient
        id="avatar-gradient"
        x1="14.0002"
        y1="0.909912"
        x2="14.0002"
        y2="27.0899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2FEE2" />
        <stop offset="1" stopColor="#75F07D" stopOpacity="0.7" />
      </linearGradient>
    ),
  },
  {
    backgroundClassName:
      "bg-[linear-gradient(180deg,#E3E0FF_0%,rgba(150,117,250,0.71)_100%),linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.1))]",
    svgGradient: (
      <linearGradient
        id="avatar-gradient"
        x1="14.0002"
        y1="0.909912"
        x2="14.0002"
        y2="27.0899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9675FA" />
        <stop offset="1" stopColor="#7E3AF2" />
      </linearGradient>
    ),
  },
  {
    backgroundClassName:
      "bg-[linear-gradient(180deg,#FDE7E7_0%,rgba(255,87,87,0.7)_100%),linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.1))]",
    svgGradient: (
      <linearGradient
        id="avatar-gradient"
        x1="14.0002"
        y1="0.909912"
        x2="14.0002"
        y2="27.0899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF5757" />
        <stop offset="1" stopColor="#E51515" />
      </linearGradient>
    ),
  },
  {
    backgroundClassName:
      "bg-[linear-gradient(180deg,#FEFEDE_0%,rgba(252,202,0,0.7)_100%),linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.1))]",
    svgGradient: (
      <linearGradient
        id="avatar-gradient"
        x1="14.0002"
        y1="0.909912"
        x2="14.0002"
        y2="27.0899"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCCA00" />
        <stop offset="1" stopColor="#E4A000" />
      </linearGradient>
    ),
  },
  {
    backgroundClassName:
      "bg-[linear-gradient(180deg,#E2FEE2_0%,rgba(117,240,125,0.7)_100%),linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.1))]",
    svgGradient: (
      <linearGradient
        id="avatar-gradient"
        x1="14.0002"
        y1="0.910156"
        x2="14.0002"
        y2="27.0902"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5BE769" />
        <stop offset="1" stopColor="#32AE43" />
      </linearGradient>
    ),
  },
];
export function UserAvatar({
  email,
  size,
}: {
  email: string;
  size: "sm" | "lg";
}) {
  const randomNumber = getNumberFromString(email);

  const shape = shapes[randomNumber % shapes.length];
  const { backgroundClassName, svgGradient } =
    colors[randomNumber % colors.length];

  return (
    <div
      className={clsx(
        "flex shrink-0 items-center justify-center rounded-full",
        backgroundClassName,
        size === "sm" ? "size-12" : "size-14",
      )}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {shape}
        <defs>
          {svgGradient}
          <filter
            id="avatar-svg-filter"
            x="0.910156"
            y="0.909912"
            width="26.1797"
            height="26.3981"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="0.218182" />
            <feGaussianBlur stdDeviation="0.109091" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            />
            <feBlend mode="normal" in2="shape" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}
