import { Place } from "common/types.ts";
import { Button } from "react-aria-components";

import ChevronRight from "fontawesome/solid/chevron-right.svg?react";
import { PlaceAvatar } from "../../common/components/PlaceAvatar";

export default function ProfilesList({
  places,
  goToPlace,
}: {
  places: Place[];
  goToPlace: (placeId: string) => void;
}) {
  return (
    <div className="flex flex-col lg:hidden">
      {places.map(({ id, name, address }) => {
        return (
          <Button
            key={id}
            onPress={() => goToPlace(id)}
            className="flex items-center gap-x-3 rounded-full p-1.5"
          >
            <PlaceAvatar placeName={name} size="lg" />
            <div className="flex grow flex-col items-start gap-y-1">
              <span className="text-base font-medium">{name}</span>
              <span className="text-xs text-grey-500">{address}</span>
            </div>
            <ChevronRight className="size-3" />
          </Button>
        );
      })}
    </div>
  );
}
