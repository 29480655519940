import SettingsItem from "@/src/pages/account/SettingsItem.tsx";
import { DropdownBox, DropdownBoxItem, Select } from "@/src/common/components";

export default function Units({
  isEditing,
  toggleIsEditing,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
}) {
  return (
    <SettingsItem
      label="Preferred Units"
      onSubmit={() => {}}
      isEditing={isEditing}
      toggleIsEditing={toggleIsEditing}
      editingComponent={
        <Select
          size="md"
          placeholder="Test"
          className="w-full md:min-w-72"
          selectedKey="Meters"
        >
          <DropdownBox
            size="sm"
            items={[{ label: "Meters" }, { label: "Not Meters" }]}
          >
            {({ label }) => (
              <DropdownBoxItem size="sm" id={label} title={label} key={label} />
            )}
          </DropdownBox>
        </Select>
      }
    >
      <span className="text-sm font-medium">Meters</span>
    </SettingsItem>
  );
}
