import { JSX, ReactNode } from "react";
import {
  Button,
  ButtonProps as AriaButtonProps,
  Group,
  Select as AriaSelect,
  SelectProps as AriaSelectProps,
  SelectValue,
} from "react-aria-components";
import AngleDown from "fontawesome/regular/angle-down.svg?react";
import XMark from "fontawesome/regular/xmark.svg?react";

import { tv, VariantProps } from "tailwind-variants";
import { clsx } from "clsx";
import { Label, Popover } from "common/components";

const selectClearButtonStyles = tv({
  base: [
    "flex items-center justify-center absolute right-[3px] items-center rounded-full transition-[background] bg-grey-50",
    "data-[hovered]:bg-grey-100",
    "data-[disabled]:bg-grey-200",
  ],
  variants: {
    size: {
      sm: "size-7 [&_svg]:size-3",
      md: "size-10 [&_svg]:size-3.5",
      lg: "size-11 [&_svg]:size-4",
    },
  },
});

const selectButtonStyles = tv({
  base: [
    "flex items-center group rounded-full border border-grey-300 p-0.5 font-medium transition-[background,color] [&>svg]:transition-[fill]",
    "aria-expanded:bg-grey-100",
    "[&_svg]:data-[hovered]:fill-grey-900 data-[hovered]:text-grey-900 data-[hovered]:bg-grey-100",
    "data-[disabled]:text-grey-400 data-[disabled]:bg-grey-200 [&_svg]:data-[disabled]:fill-grey-400",
    "data-[focus-visible]:outline data-[focus-visible]:outline-3 data-[focus-visible]:outline-purple-100 data-[focus-visible]:border-purple-400",
  ],
  variants: {
    size: {
      sm: "h-8 pl-4 gap-x-2 text-xs [&_svg]:size-3 [&>div]:size-7 [&_svg]:-mx-1",
      md: "h-11 pl-5 gap-x-2.5 text-sm [&_svg]:size-3.5 [&>div]:size-10 [&_svg]:-mx-1",
      lg: "h-12 pl-6 gap-x-3 text-base [&_svg]:size-4 [&>div]:size-11 [&_svg]:-mx-1.5",
    },
  },
});
interface SelectButtonProps
  extends Omit<AriaButtonProps, "children" | "className">,
    VariantProps<typeof selectButtonStyles> {
  leftIcon?: JSX.Element;
  className?: string;
  children?: ReactNode;
  placeholder?: string;
  onClear?: () => void;
}
export function SelectButton({
  leftIcon,
  children,
  size,
  placeholder,
  className,
  onClear,
  isDisabled,
  ...rest
}: SelectButtonProps) {
  return (
    <Group className="relative flex items-center">
      <Button
        className={selectButtonStyles({
          size,
          className: clsx(
            children
              ? "text-grey-700 [&>svg]:fill-grey-700"
              : "text-grey-400 [&>svg]:fill-grey-500",
            className,
          ),
        })}
        isDisabled={isDisabled}
        {...rest}
      >
        {leftIcon}
        {children || placeholder}
        <div
          aria-hidden="true"
          className="ml-auto flex items-center justify-center rounded-full bg-grey-50 transition-[background] group-aria-expanded:bg-white group-aria-expanded:text-grey-900 group-data-[disabled]:bg-grey-200"
        >
          <AngleDown className="size-3.5 fill-grey-900 transition-transform group-aria-expanded:rotate-180 group-data-[disabled]:fill-grey-400" />
        </div>
      </Button>
      {onClear && (
        <Button
          slot={null}
          className={clsx("group", selectClearButtonStyles({ size }))}
          isDisabled={isDisabled}
          onPress={onClear}
        >
          <XMark className="transition-[fill] group-data-[disabled]:fill-grey-400" />
        </Button>
      )}
    </Group>
  );
}
interface SelectProps<T extends object>
  extends AriaSelectProps<T>,
    Pick<SelectButtonProps, "size" | "leftIcon" | "onClear"> {
  label?: string;
}

export function Select<T extends object>({
  children,
  size,
  leftIcon,
  isDisabled,
  onClear,
  placeholder,
  selectedKey,
  label,
  ...rest
}: SelectProps<T>) {
  const labelComponent = label && <Label size={size}>{label}</Label>;
  const buttonComponent = (
    <SelectButton
      leftIcon={leftIcon}
      size={size}
      isDisabled={isDisabled}
      className="grow"
      placeholder={placeholder}
      onClear={onClear}
    >
      {selectedKey && <SelectValue />}
    </SelectButton>
  );

  return (
    <AriaSelect
      selectedKey={selectedKey}
      isDisabled={isDisabled}
      placeholder={placeholder}
      {...rest}
    >
      {typeof children === "function" ? (
        (renderProps) => (
          <>
            {labelComponent}
            {buttonComponent}
            <Popover className="w-[--trigger-width]">
              {children(renderProps)}
            </Popover>
          </>
        )
      ) : (
        <>
          {labelComponent}
          {buttonComponent}
          <Popover className="w-[--trigger-width]">{children}</Popover>
        </>
      )}
    </AriaSelect>
  );
}
