import { Label, Slider, Select } from "@/src/common/components";

export default function DrivesSettings() {
  return (
    <>
      <Select
        size="lg"
        className="w-full"
        label="Daily drives"
        placeholder="Select number of daily drives"
      />
      <Select
        label="Drives working hours"
        size="lg"
        className="w-full"
        placeholder="Select drives working hours"
      />
      <Slider className="w-full" maxValue={20} step={5}>
        <Label size="lg">Radius size</Label>
      </Slider>
    </>
  );
}
