import { createFileRoute } from "@tanstack/react-router";
import "mapbox-gl/dist/mapbox-gl.css";
import usePlace from "@/src/common/datahooks/usePlace.tsx";
import DateSelector from "@/src/pages/home/DateSelector/DateSelector.tsx";
import { useSelectedPlace } from "@/src/contexts.ts";
import { Button, Badge } from "@/src/common/components";

import Sliders from "fontawesome/solid/sliders.svg?react";
import Ruler from "fontawesome/solid/ruler-horizontal.svg?react";
import Calendar from "fontawesome/solid/calendar.svg?react";
import KeywordSelect from "@/src/pages/home/KeywordSelect.tsx";
import useKeywordSnapshots from "@/src/common/datahooks/useKeywordSnapshots.tsx";
import { useEffect } from "react";
import GridAndDrivesSettings from "@/src/common/components/GridAndDrivesSettings/GridAndDrivesSettings.tsx";
import { useTwBreakpoint } from "common/hooks";
import GridArea from "@/src/pages/home/GridArea";
import { parseDate } from "@internationalized/date";
import { formatDayAndMonth } from "common/utils.ts";
import { MONTH_NAMES } from "@/src/constants.tsx";

type SearchParams = {
  selectedKeywordId?: string;
  selectedDate?: string;
  compareWithDate?: string;
};
export const Route = createFileRoute("/$placeId/_layout/home")({
  component: Home,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const searchObject: SearchParams = {};
    if (search.selectedKeywordId) {
      searchObject.selectedKeywordId = search.selectedKeywordId as string;
    }
    if (search.selectedDate) {
      searchObject.selectedDate = search.selectedDate as string;
    }
    return searchObject;
  },
  staticData: {
    pageTitle: "Drives",
  },
});

const pageSize = 10;

function Home() {
  const navigate = Route.useNavigate();
  const isDesktop = useTwBreakpoint("lg");
  const { selectedPlace } = useSelectedPlace();
  const { selectedKeywordId, selectedDate, compareWithDate } =
    Route.useSearch();
  const { placeId } = Route.useParams();
  const { place } = usePlace(placeId);

  const keyword =
    place && place.keywords.find(({ id }) => id === selectedKeywordId);
  const selectedDateCalendarDate = selectedDate
    ? parseDate(selectedDate)
    : undefined;

  const { keywordSnapshots, fetchNextPage, isLoadingKeywordSnapshots } =
    useKeywordSnapshots(placeId, keyword, pageSize);

  useEffect(() => {
    if (!selectedDate && keywordSnapshots && keywordSnapshots.length) {
      navigate({
        search: (prevSearch) => ({
          ...prevSearch,
          selectedDate: keywordSnapshots[0].date,
          compareWithDate: keywordSnapshots[1] && keywordSnapshots[1].date,
        }),
      });
    }
  }, [keywordSnapshots]);

  useEffect(() => {
    if (
      !selectedKeywordId &&
      place &&
      place.keywords.length &&
      ["ready", "failed"].includes(place.keywords[0].grid_state)
    ) {
      setTimeout(() => {
        navigate({
          search: (prevSearch) => ({
            ...prevSearch,
            selectedKeywordId: place.keywords[0].id,
          }),
        });
      }, 1);
    }
  }, [selectedKeywordId, place]);

  const gridAndDrivesSettingsComponent = (
    <GridAndDrivesSettings
      initialTab="grid"
      renderTriggerComponent={(onPress) => (
        <Button
          variant="black"
          size="lg"
          onPress={onPress}
          isIconOnly={!isDesktop}
        >
          {isDesktop && "Manage grid"}
          <Sliders />
        </Button>
      )}
    />
  );
  const keywordSelectComponent = (
    <KeywordSelect
      data={
        place && {
          keywords: place.keywords,
          maxKeywords: place.subscription.item.max_keywords,
        }
      }
      selectedKeywordId={selectedKeywordId}
      isDisabled={selectedPlace.subscription.status === "cancelled"}
    />
  );
  const dateSelectorComponent = (
    <DateSelector
      snapshots={keywordSnapshots}
      loadMore={fetchNextPage}
      isLoading={isLoadingKeywordSnapshots}
    />
  );
  const gridAreaComponent = (
    <GridArea selectedKeyword={keyword} snapshots={keywordSnapshots} />
  );

  if (isDesktop) {
    return (
      <div className="rounded-7xl bg-grey-50 max-lg:hidden">
        <div className="mb-5 flex items-center justify-between px-6 pt-6">
          <div className="flex flex-col gap-y-1">
            <h3 className="text-xl font-semibold">Analytics</h3>
            <div className="flex gap-x-1">
              <Badge variant="grey" size="md">
                <Ruler />
                Grid distance: 25m
              </Badge>
              {selectedDateCalendarDate && (
                <Badge variant="stroke" size="md">
                  <Calendar />
                  {compareWithDate && (
                    <span className="text-grey-500">{`${formatDayAndMonth(parseDate(compareWithDate))} | `}</span>
                  )}
                  {formatDayAndMonth(selectedDateCalendarDate)}
                </Badge>
              )}
            </div>
          </div>
          <div className="flex gap-x-2">
            {keywordSelectComponent}
            {gridAndDrivesSettingsComponent}
          </div>
        </div>
        <div className="rounded-7xl bg-white p-2 pt-5">
          {dateSelectorComponent}
          {gridAreaComponent}
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-3 rounded-4xl bg-white p-3">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold">Analytics</h3>
          {gridAndDrivesSettingsComponent}
        </div>
        {keywordSelectComponent}
      </div>
      <div className="flex flex-col gap-y-3 rounded-4xl bg-white py-3">
        {selectedDateCalendarDate && (
          <span className="ml-3 text-lg font-medium">
            {MONTH_NAMES[selectedDateCalendarDate.month - 1]}{" "}
            {selectedDateCalendarDate.year}
          </span>
        )}
        {dateSelectorComponent}
      </div>
      {gridAreaComponent}
    </div>
  );
}
