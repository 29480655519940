import {
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
  ListBoxProps as AriaListBoxProps,
  ListBoxItemProps as AriaListBoxItemProps,
  Button,
  ButtonProps,
} from "react-aria-components";
import { JSX } from "react";
import { tv, VariantProps } from "tailwind-variants";
import { clsx } from "clsx";

export const listBoxStyle = tv({
  base: "overflow-y-auto flex flex-col",
  variants: { size: { sm: "p-2 max-h-[232px]", lg: "p-2.5 max-h-[284px]" } },
});

interface ListBoxProps<T>
  extends AriaListBoxProps<T>,
    VariantProps<typeof listBoxStyle> {}

export function DropdownBox<T extends object>({
  className,
  size,
  ...rest
}: ListBoxProps<T>) {
  return (
    <AriaListBox<T>
      className={
        typeof className === "function"
          ? (renderProps) =>
              listBoxStyle({ size, className: className(renderProps) })
          : listBoxStyle({ size, className })
      }
      {...rest}
    />
  );
}

export const listBoxItemStyle = tv({
  base: [
    "group cursor-pointer flex [&_svg]:fill-grey-600 outline-none font-medium text-grey-600",
    "data-[hovered]:bg-grey-50 [&_svg]:data-[hovered]:fill-grey-900 data-[hovered]:text-grey-900",
    "data-[focused]:bg-grey-50 [&_svg]:data-[focused]:fill-grey-900 data-[focused]:text-grey-900",
  ],
  variants: {
    size: {
      sm: "gap-x-1.5 p-2 rounded-xl [&_svg]:size-3.5 [&_svg]:mt-0.5 text-sm",
      lg: "gap-x-2 p-2.5 rounded-2xl [&_svg]:size-4 [&_svg]:mt-1 text-base",
    },
  },
});

interface ListBoxItemProps
  extends AriaListBoxItemProps,
    VariantProps<typeof listBoxItemStyle> {
  title: string;
  subtitle?: string;
  leftComponent?: JSX.Element;
  rightComponent?: JSX.Element;
}

export function DropdownBoxItem({
  className,
  size,
  title,
  subtitle,
  leftComponent,
  rightComponent,
  ...rest
}: ListBoxItemProps) {
  return (
    <AriaListBoxItem
      textValue={title}
      className={
        typeof className === "function"
          ? (renderProps) =>
              listBoxItemStyle({ size, className: className(renderProps) })
          : listBoxItemStyle({ size, className })
      }
      {...rest}
    >
      {leftComponent}
      <div className="flex grow flex-col">
        <span>{title}</span>
        <span className="text-xs text-grey-400">{subtitle}</span>
      </div>
      {rightComponent}
    </AriaListBoxItem>
  );
}
export function DropdownActionButton({
  size,
  children,
  ...rest
}: Omit<ButtonProps, "className"> & { size: "sm" | "lg" }) {
  return (
    <Button
      {...rest}
      className={clsx(
        size === "sm"
          ? "h-11 gap-x-1.5 px-4 text-sm"
          : "h-12 gap-x-2 px-4.5 text-base",
        Array.isArray(children) && children.length > 2
          ? "last:[&_svg]:ml-auto"
          : "justify-start",
        "first:rounded-t-3xl first:border-b first:border-b-grey-200",
        "last:rounded-b-3xl last:border-t last:border-t-grey-200",
        "flex w-full shrink-0 items-center font-medium outline-none data-[focused]:bg-grey-100 data-[hovered]:bg-grey-100 [&_svg]:size-4",
      )}
      slot={null}
    >
      {children}
    </Button>
  );
}
