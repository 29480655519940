import SettingsItem from "@/src/pages/account/SettingsItem.tsx";
import useCustomer from "@/src/common/datahooks/useCustomer.ts";
import { Input } from "common/components";

export default function Email({
  isEditing,
  toggleIsEditing,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
}) {
  const { customer } = useCustomer();

  return (
    <SettingsItem
      label="Email"
      onSubmit={() => {}}
      isEditing={isEditing}
      toggleIsEditing={toggleIsEditing}
      editingComponent={
        customer && (
          <Input
            size="md"
            value={customer.email}
            className="w-full md:min-w-72"
            autoFocus
          />
        )
      }
    >
      {customer ? (
        <span className="text-sm font-medium text-grey-500">
          {customer.email}
        </span>
      ) : (
        <span className="text-sm">Loading...</span>
      )}
    </SettingsItem>
  );
}
