import KeywordsTable from "@/src/pages/home/KeywordsModal/KeywordsTable.tsx";
import { Keyword } from "common/types.ts";

export default function AddKeywords({
  searchText,
  keywords,
  maxKeywords,
}: {
  searchText: string;
  keywords: Keyword[];
  maxKeywords: number;
}) {
  if (searchText) {
    return (
      <KeywordsTable
        keywords={keywords}
        maxKeywords={maxKeywords}
        keywordSearchResults={[
          {
            id: "test",
            label: searchText,
          },
        ]}
      />
    );
  }
  return (
    <div className="flex flex-col">
      <h3 className="mb-2.5 text-center text-xl font-medium">
        Search for new keywords here
      </h3>
      <p className="text-center text-base text-grey-500">
        Lorem ipsum dolor sit amet consectetur. Etiam suscipit sit diam in.
      </p>
    </div>
  );
}
