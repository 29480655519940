/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LayoutImport } from './routes/_layout'
import { Route as LayoutProfilesImport } from './routes/_layout/profiles'
import { Route as LayoutAccountImport } from './routes/_layout/account'
import { Route as PlaceIdLayoutImport } from './routes/$placeId/_layout'
import { Route as PlaceIdLayoutSettingsImport } from './routes/$placeId/_layout/settings'
import { Route as PlaceIdLayoutHomeImport } from './routes/$placeId/_layout/home'
import { Route as PlaceIdLayoutDrivesImport } from './routes/$placeId/_layout/drives'

// Create Virtual Routes

const PlaceIdImport = createFileRoute('/$placeId')()

// Create/Update Routes

const PlaceIdRoute = PlaceIdImport.update({
  id: '/$placeId',
  path: '/$placeId',
  getParentRoute: () => rootRoute,
} as any)

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const LayoutProfilesRoute = LayoutProfilesImport.update({
  id: '/profiles',
  path: '/profiles',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutAccountRoute = LayoutAccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => LayoutRoute,
} as any)

const PlaceIdLayoutRoute = PlaceIdLayoutImport.update({
  id: '/_layout',
  getParentRoute: () => PlaceIdRoute,
} as any)

const PlaceIdLayoutSettingsRoute = PlaceIdLayoutSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => PlaceIdLayoutRoute,
} as any)

const PlaceIdLayoutHomeRoute = PlaceIdLayoutHomeImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => PlaceIdLayoutRoute,
} as any)

const PlaceIdLayoutDrivesRoute = PlaceIdLayoutDrivesImport.update({
  id: '/drives',
  path: '/drives',
  getParentRoute: () => PlaceIdLayoutRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/$placeId': {
      id: '/$placeId'
      path: '/$placeId'
      fullPath: '/$placeId'
      preLoaderRoute: typeof PlaceIdImport
      parentRoute: typeof rootRoute
    }
    '/$placeId/_layout': {
      id: '/$placeId/_layout'
      path: '/$placeId'
      fullPath: '/$placeId'
      preLoaderRoute: typeof PlaceIdLayoutImport
      parentRoute: typeof PlaceIdRoute
    }
    '/_layout/account': {
      id: '/_layout/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof LayoutAccountImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/profiles': {
      id: '/_layout/profiles'
      path: '/profiles'
      fullPath: '/profiles'
      preLoaderRoute: typeof LayoutProfilesImport
      parentRoute: typeof LayoutImport
    }
    '/$placeId/_layout/drives': {
      id: '/$placeId/_layout/drives'
      path: '/drives'
      fullPath: '/$placeId/drives'
      preLoaderRoute: typeof PlaceIdLayoutDrivesImport
      parentRoute: typeof PlaceIdLayoutImport
    }
    '/$placeId/_layout/home': {
      id: '/$placeId/_layout/home'
      path: '/home'
      fullPath: '/$placeId/home'
      preLoaderRoute: typeof PlaceIdLayoutHomeImport
      parentRoute: typeof PlaceIdLayoutImport
    }
    '/$placeId/_layout/settings': {
      id: '/$placeId/_layout/settings'
      path: '/settings'
      fullPath: '/$placeId/settings'
      preLoaderRoute: typeof PlaceIdLayoutSettingsImport
      parentRoute: typeof PlaceIdLayoutImport
    }
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  LayoutAccountRoute: typeof LayoutAccountRoute
  LayoutProfilesRoute: typeof LayoutProfilesRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutAccountRoute: LayoutAccountRoute,
  LayoutProfilesRoute: LayoutProfilesRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

interface PlaceIdLayoutRouteChildren {
  PlaceIdLayoutDrivesRoute: typeof PlaceIdLayoutDrivesRoute
  PlaceIdLayoutHomeRoute: typeof PlaceIdLayoutHomeRoute
  PlaceIdLayoutSettingsRoute: typeof PlaceIdLayoutSettingsRoute
}

const PlaceIdLayoutRouteChildren: PlaceIdLayoutRouteChildren = {
  PlaceIdLayoutDrivesRoute: PlaceIdLayoutDrivesRoute,
  PlaceIdLayoutHomeRoute: PlaceIdLayoutHomeRoute,
  PlaceIdLayoutSettingsRoute: PlaceIdLayoutSettingsRoute,
}

const PlaceIdLayoutRouteWithChildren = PlaceIdLayoutRoute._addFileChildren(
  PlaceIdLayoutRouteChildren,
)

interface PlaceIdRouteChildren {
  PlaceIdLayoutRoute: typeof PlaceIdLayoutRouteWithChildren
}

const PlaceIdRouteChildren: PlaceIdRouteChildren = {
  PlaceIdLayoutRoute: PlaceIdLayoutRouteWithChildren,
}

const PlaceIdRouteWithChildren =
  PlaceIdRoute._addFileChildren(PlaceIdRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof LayoutRouteWithChildren
  '/$placeId': typeof PlaceIdLayoutRouteWithChildren
  '/account': typeof LayoutAccountRoute
  '/profiles': typeof LayoutProfilesRoute
  '/$placeId/drives': typeof PlaceIdLayoutDrivesRoute
  '/$placeId/home': typeof PlaceIdLayoutHomeRoute
  '/$placeId/settings': typeof PlaceIdLayoutSettingsRoute
}

export interface FileRoutesByTo {
  '': typeof LayoutRouteWithChildren
  '/$placeId': typeof PlaceIdLayoutRouteWithChildren
  '/account': typeof LayoutAccountRoute
  '/profiles': typeof LayoutProfilesRoute
  '/$placeId/drives': typeof PlaceIdLayoutDrivesRoute
  '/$placeId/home': typeof PlaceIdLayoutHomeRoute
  '/$placeId/settings': typeof PlaceIdLayoutSettingsRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/$placeId': typeof PlaceIdRouteWithChildren
  '/$placeId/_layout': typeof PlaceIdLayoutRouteWithChildren
  '/_layout/account': typeof LayoutAccountRoute
  '/_layout/profiles': typeof LayoutProfilesRoute
  '/$placeId/_layout/drives': typeof PlaceIdLayoutDrivesRoute
  '/$placeId/_layout/home': typeof PlaceIdLayoutHomeRoute
  '/$placeId/_layout/settings': typeof PlaceIdLayoutSettingsRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/$placeId'
    | '/account'
    | '/profiles'
    | '/$placeId/drives'
    | '/$placeId/home'
    | '/$placeId/settings'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/$placeId'
    | '/account'
    | '/profiles'
    | '/$placeId/drives'
    | '/$placeId/home'
    | '/$placeId/settings'
  id:
    | '__root__'
    | '/_layout'
    | '/$placeId'
    | '/$placeId/_layout'
    | '/_layout/account'
    | '/_layout/profiles'
    | '/$placeId/_layout/drives'
    | '/$placeId/_layout/home'
    | '/$placeId/_layout/settings'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren
  PlaceIdRoute: typeof PlaceIdRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
  PlaceIdRoute: PlaceIdRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout",
        "/$placeId"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/account",
        "/_layout/profiles"
      ]
    },
    "/$placeId": {
      "filePath": "$placeId",
      "children": [
        "/$placeId/_layout"
      ]
    },
    "/$placeId/_layout": {
      "filePath": "$placeId/_layout.tsx",
      "parent": "/$placeId",
      "children": [
        "/$placeId/_layout/drives",
        "/$placeId/_layout/home",
        "/$placeId/_layout/settings"
      ]
    },
    "/_layout/account": {
      "filePath": "_layout/account.tsx",
      "parent": "/_layout"
    },
    "/_layout/profiles": {
      "filePath": "_layout/profiles.tsx",
      "parent": "/_layout"
    },
    "/$placeId/_layout/drives": {
      "filePath": "$placeId/_layout/drives.tsx",
      "parent": "/$placeId/_layout"
    },
    "/$placeId/_layout/home": {
      "filePath": "$placeId/_layout/home.tsx",
      "parent": "/$placeId/_layout"
    },
    "/$placeId/_layout/settings": {
      "filePath": "$placeId/_layout/settings.tsx",
      "parent": "/$placeId/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
