import { ReactNode } from "react";
import { RankedPlace } from "common/types.ts";
import { getRankColorClassnames, getRankLabel } from "common/utils.ts";
import StarFullIcon from "fontawesome/solid/star.svg?react";
import { Badge } from "@/src/common/components";
import { clsx } from "clsx";

export default function PlacesListItem({ place }: { place?: RankedPlace }) {
  const labelComponents: ReactNode[] = [];

  if (place) {
    labelComponents.push(
      <Badge size="sm" variant="grey" key={place.primary_category}>
        {place.primary_category}
      </Badge>,
    );
    place.categories.forEach((category) => {
      labelComponents.push(
        <Badge size="sm" variant="grey" key={category}>
          {category}
        </Badge>,
      );
    });
  } else {
    for (let i = 0; i < 3; i++) {
      labelComponents.push(
        <div
          key={i}
          className="h-[18px] w-[100px] animate-pulse rounded-md bg-grey-300"
        />,
      );
    }
    labelComponents.push(
      <div
        key={labelComponents.length}
        className="h-[18px] w-[40px] animate-pulse rounded-md bg-grey-300"
      />,
    );
  }
  return (
    <div className="flex flex-col border-t border-t-grey-300 py-4 last:border-b-0">
      <div className="mb-4 flex items-center gap-2">
        {place ? (
          <div
            className={clsx(
              "flex size-8 items-center justify-center rounded-full",
              getRankColorClassnames(place.average_rank).bg,
            )}
          >
            <span className="text-xs font-medium text-grey-900">
              {getRankLabel(place.average_rank)}
            </span>
          </div>
        ) : (
          <div className="size-8 animate-pulse rounded-full bg-grey-300" />
        )}
        <div className="flex grow flex-row items-center justify-between gap-y-1">
          {place ? (
            <span className="text-base font-medium">{place.name}</span>
          ) : (
            <div className="h-6 w-36 animate-pulse rounded-2xl bg-grey-300" />
          )}
          {place ? (
            <div className="flex items-center gap-x-1">
              <StarFullIcon className="size-4 fill-yellow-300" />
              <span className="text-sm font-medium">{place.rating}</span>
              <span className="text-sm font-normal text-grey-500">
                ({place.rating_count})
              </span>
            </div>
          ) : (
            <StarFullIcon className="size-4 animate-pulse fill-grey-300" />
          )}
        </div>
      </div>
      <div className="flex flex-wrap gap-2">{labelComponents}</div>
    </div>
  );
}
