import { Badge } from "common/components";

import PaymentMethod from "@/src/pages/settings/PaymentMethod.tsx";
import NoSubscriptionPlaceholder from "@/src/pages/settings/NoSubscriptionPlaceholder.tsx";
import { useTwBreakpoint } from "common/hooks";
import ManageSubscription from "@/src/pages/settings/ManageSubscription.tsx";

export default function Subscription() {
  const isDesktop = useTwBreakpoint("lg");

  const hasSubscription = false;

  const subscriptionHeaderComponent = (
    <div className="flex flex-col lg:flex-row">
      <h3 className="text-lg font-semibold lg:text-xl">
        Subscription: {hasSubscription && "Default"}
      </h3>
    </div>
  );
  const manageSubscriptionComponent = hasSubscription && <ManageSubscription />;
  const subscriptionDetailsComponent = hasSubscription ? (
    <div className="col-span-2 flex flex-wrap gap-1">
      <Badge size="md" variant="purple">
        Grid size: 7x7
      </Badge>
      <Badge size="md" variant="purple">
        Frequency: 4 days
      </Badge>
      <Badge size="md" variant="purple">
        Keyword number:20
      </Badge>
      <Badge size="md" variant="purple">
        Keyword number:20
      </Badge>
      <Badge size="md" variant="grey">
        Grid distance: 25m
      </Badge>
      <Badge size="md" variant="grey">
        Radius: 20km
      </Badge>
    </div>
  ) : (
    <span className="text-sm font-medium text-grey-500">No subscription</span>
  );

  return (
    <div className="flex flex-col rounded-4xl bg-grey-50 lg:rounded-7xl">
      <div className="flex flex-col p-3 lg:p-6 lg:pb-5">
        {isDesktop ? (
          <div className="flex items-center justify-between">
            <div className="flex flex-col gap-y-1">
              {subscriptionHeaderComponent}
              {subscriptionDetailsComponent}
            </div>
            {manageSubscriptionComponent}
          </div>
        ) : (
          <div className="flex flex-col gap-y-2">
            <div className="flex justify-between">
              {subscriptionHeaderComponent}
              {manageSubscriptionComponent}
            </div>
            {subscriptionDetailsComponent}
          </div>
        )}
        {hasSubscription && (
          <div className="mt-2 flex flex-col justify-between border-t border-t-grey-200 pt-2 max-lg:gap-y-2 lg:mt-4 lg:flex-row lg:pt-4">
            <span className="text-sm">
              Tour next payment is{" "}
              <span className="font-semibold">$59.00 USD,</span> to be charged
              on <span className="font-semibold">April 08, 2025</span>
            </span>
            <span className="text-xs text-grey-500">
              Your payment will automatically renew each month
            </span>
          </div>
        )}
      </div>
      {hasSubscription ? <PaymentMethod /> : <NoSubscriptionPlaceholder />}
    </div>
  );
}
