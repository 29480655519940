import { ReactNode, useEffect, useState } from "react";
import {
  Pagination,
  Badge,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Button,
  TableHeader,
} from "@/src/common/components";
import { SortDescriptor, SortDirection, Table } from "react-aria-components";
import { Keyword, KeywordSearchResult } from "common/types.ts";

import Trash from "fontawesome/regular/trash-can.svg?react";
import ArrowRight from "fontawesome/regular/arrow-right.svg?react";
import AddKeyword from "@/src/pages/home/KeywordsModal/AddKeyword.tsx";
import { useNavigate } from "@tanstack/react-router";

const keywordsPerPage = 5;

const columns = [
  { name: "Keyword", id: "label", isRowHeader: true, allowsSorting: true },
  { name: "Keyword difficulty", id: "difficulty", allowsSorting: true },
  { name: "Search volume", id: "search_volume", allowsSorting: true },
  { name: "", id: "actions", className: "w-1/12" },
];

export default function KeywordsTable({
  keywords,
  closeModal,
  keywordSearchResults,
  maxKeywords,
}: { keywords: Keyword[] } & (
  | {
      keywordSearchResults: KeywordSearchResult[];
      maxKeywords: number;
      closeModal?: never;
    }
  | {
      keywordSearchResults?: never;
      maxKeywords?: never;
      closeModal: () => void;
    }
)) {
  const navigate = useNavigate({ from: "/$placeId/home" });
  const [sortDescriptor, setSortDescriptor] = useState<SortDescriptor>({
    column: "search_volume",
    direction: "descending",
  });
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setPageIndex(0);
  }, [keywords, sortDescriptor]);

  const tableData = keywordSearchResults || keywords;
  let tableContent: ReactNode[] = [];
  if (keywordSearchResults) {
    const paginatedData = keywordSearchResults.slice(
      pageIndex * keywordsPerPage,
      pageIndex * keywordsPerPage + keywordsPerPage,
    );
    tableContent = paginatedData.map(({ label }) => {
      return (
        <TableRow className="h-16">
          <TableCell className="font-medium">{label}</TableCell>
          <TableCell className="text-xs font-medium text-grey-500">
            There is no information on this yet
          </TableCell>
          <TableCell>/</TableCell>
          <TableCell>
            <AddKeyword
              keywordLabel={label}
              isLimitReached={keywords.length >= maxKeywords}
            />
          </TableCell>
        </TableRow>
      );
    });
  } else if (keywords.length) {
    const sortedKeywords = sortDescriptor.column
      ? [...keywords].sort((a, b) => {
          if (sortDescriptor.direction === "ascending" && sortDescriptor) {
            return a[sortDescriptor.column as keyof Keyword] >
              b[sortDescriptor.column as keyof Keyword]
              ? 1
              : -1;
          } else {
            return a[sortDescriptor.column as keyof Keyword] <
              b[sortDescriptor.column as keyof Keyword]
              ? 1
              : -1;
          }
        })
      : keywords;
    const paginatedData = sortedKeywords.slice(
      pageIndex * keywordsPerPage,
      pageIndex * keywordsPerPage + keywordsPerPage,
    );
    tableContent = paginatedData.map(
      ({ id, search_volume, label, difficulty, state }) => {
        let difficultyContent: ReactNode = "N/A";
        let searchVolumeContent = "N/A";

        if (state === "ready") {
          difficultyContent = (
            <div className="flex">
              <Badge variant="grey" size="sm">
                {difficulty}
              </Badge>
            </div>
          );
          searchVolumeContent = search_volume.toString();
        }

        return (
          <TableRow className="h-16" id={id}>
            <TableCell className="font-medium">{label}</TableCell>
            <TableCell>{difficultyContent}</TableCell>
            <TableCell>{searchVolumeContent || "/"}</TableCell>
            <TableCell>
              <div className="flex items-center gap-x-2">
                <Button variant="transparent" size="lg" isIconOnly>
                  <Trash />
                </Button>
                <Button variant="transparent" size="lg" isIconOnly>
                  <ArrowRight />
                </Button>
              </div>
            </TableCell>
          </TableRow>
        );
      },
    );
  }

  return (
    <div className="hidden flex-col lg:flex">
      <Table
        aria-label="Keywords"
        sortDescriptor={sortDescriptor}
        onSortChange={(descriptor) =>
          setSortDescriptor({
            column: descriptor.column as keyof Keyword,
            direction: descriptor.direction as SortDirection,
          })
        }
        onRowAction={(key) => {
          const keyword = keywords.find(({ id }) => id === key);
          if (keyword) {
            navigate({
              search: (prevSearch) => ({
                ...prevSearch,
                selectedKeywordId: keyword.id,
              }),
            });
            closeModal && closeModal();
          }
        }}
      >
        <TableHeader columns={columns}>
          {({ name, isRowHeader, allowsSorting, className }) => (
            <TableColumn
              isRowHeader={isRowHeader}
              key={name}
              allowsSorting={keywordSearchResults ? false : allowsSorting}
              className={className}
            >
              {name}
            </TableColumn>
          )}
        </TableHeader>
        <TableBody className="divide-y">{tableContent}</TableBody>
      </Table>
      {tableData.length > keywordsPerPage && (
        <Pagination
          size="sm"
          className="mx-auto my-4"
          pagesCount={Math.ceil(tableData.length / keywordsPerPage)}
          setPageIndex={(index) => setPageIndex(index)}
          pageIndex={pageIndex}
        />
      )}
    </div>
  );
}
