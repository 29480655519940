import { init, replayIntegration } from "@sentry/react";

if (import.meta.env.MODE === "production") {
  init({
    dsn: "https://78f73c88d19cab39303d9d774bb8abee@o4507312289284096.ingest.us.sentry.io/4507368857403392",
    integrations: [replayIntegration()],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
