import { Drive } from "common/types.ts";
import { get } from "common/helpers/HTTP.ts";
import { useQuery } from "@tanstack/react-query";
import { dummyDrives, getDummyPlace } from "@/src/dummyGenerator.ts";

async function getDrives(placeId: string): Promise<Drive[]> {
  if (getDummyPlace(placeId)) {
    return dummyDrives;
  }
  const { drives } = await get(`v1/places/${placeId}/drives`);
  return drives;
}
export default function useDrives(placeId: string) {
  const { data: drives, isLoading: isLoadingDrives } = useQuery({
    queryKey: ["drives"],
    queryFn: () => getDrives(placeId),
  });
  return { drives, isLoadingDrives };
}
