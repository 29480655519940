import {
  Button,
  listBoxStyle,
  Popover,
  ButtonLink,
  UserAvatar,
  RouterLink,
  listBoxItemStyle,
  PopoverDialog,
} from "@/src/common/components";
import { clsx } from "clsx";
import { logout } from "@/src/common/helpers/auth.ts";

import Logo from "@/src/assets/logo.svg?react";
import ArrowLeftIcon from "fontawesome/regular/arrow-left-to-line.svg?react";
import ArrowRightIcon from "fontawesome/regular/arrow-right-to-line.svg?react";
import UserGear from "fontawesome/regular/user-gear.svg?react";
import ArrowRightFromBracket from "fontawesome/regular/arrow-right-from-bracket.svg?react";

import { DialogTrigger, Button as AriaButton } from "react-aria-components";
import { routes } from "@/src/constants.tsx";
import useCustomer from "common/datahooks/useCustomer.ts";

export default function DesktopNavBar({
  isNavExpanded,
  setIsNavExpanded,
}: {
  isNavExpanded: boolean;
  setIsNavExpanded: (isNavExpanded: boolean) => void;
}) {
  const { customer } = useCustomer();

  return (
    <nav
      className={clsx(
        "z fixed bottom-0 left-0 flex h-full flex-col p-6 pt-5 transition-[width]",
        isNavExpanded ? "w-72" : "w-24",
      )}
    >
      <div className="mb-14 flex items-center gap-x-1.5">
        <Logo className="w-12" />
        <span
          className={clsx(
            "text-2xl font-semibold transition-[opacity]",
            isNavExpanded ? "opacity-100" : "opacity-0",
          )}
        >
          HeyRank
        </span>
      </div>
      <div className="flex flex-col space-y-4">
        {routes.map(({ label, icon }) => {
          return (
            <ButtonLink
              key={label}
              to={`/$placeId/${label}`}
              size="lg"
              activeProps={{
                variant: "black",
              }}
              inactiveProps={{
                variant: "white",
              }}
              isIconOnly={!isNavExpanded}
              className={clsx(
                "w-full justify-start overflow-hidden capitalize",
                !isNavExpanded && "pl-5.5",
              )}
            >
              {icon}
              <span
                className={clsx(
                  isNavExpanded ? "opacity-100" : "opacity-0",
                  "transition-[opacity]",
                )}
              >
                {label}
              </span>
            </ButtonLink>
          );
        })}
      </div>
      <Button
        size="lg"
        variant="stroke"
        isIconOnly={!isNavExpanded}
        className={clsx(
          "mt-auto w-full justify-start overflow-hidden capitalize",
          !isNavExpanded && "pl-5.5",
        )}
        onPress={() => setIsNavExpanded(!isNavExpanded)}
      >
        {isNavExpanded ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        <span
          className={
            isNavExpanded ? "opacity-100" : "w-0 overflow-hidden opacity-0"
          }
        >
          Collapse
        </span>
      </Button>
      <DialogTrigger>
        <AriaButton
          className={clsx(
            "flex gap-x-4 overflow-hidden rounded-full bg-white transition-[padding,margin]",
            isNavExpanded ? "mt-3 p-1 pr-4" : "mt-5",
          )}
        >
          {customer && (
            <>
              <UserAvatar email={customer.email} size="sm" />
              <div
                className={clsx(
                  "flex grow flex-col gap-y-1 overflow-hidden transition-[opacity]",
                  isNavExpanded ? "opacity-100" : "opacity-0",
                )}
              >
                <span className="truncate text-left text-base font-medium">
                  {customer.first_name} {customer.last_name}
                </span>
                <span className="truncate text-left text-xs font-medium text-grey-500">
                  {customer.email}
                </span>
              </div>
            </>
          )}
        </AriaButton>
        <Popover placement="right">
          <PopoverDialog>
            <div className={listBoxStyle({ size: "lg" })}>
              <RouterLink
                className={listBoxItemStyle({ size: "lg" })}
                to="/account"
              >
                <UserGear />
                Profile settings
              </RouterLink>
              <AriaButton
                className={listBoxItemStyle({ size: "lg" })}
                onPress={logout}
              >
                <ArrowRightFromBracket />
                Log out
              </AriaButton>
            </div>
          </PopoverDialog>
        </Popover>
      </DialogTrigger>
    </nav>
  );
}
