import { Coordinates, Keyword, RankedPlace } from "common/types.ts";
import { get } from "common/helpers/HTTP.ts";
import { useQuery } from "@tanstack/react-query";
import { getDummyPlace, getDummyPointPlaces } from "@/src/dummyGenerator.ts";

type RequestParams = {
  date: string | undefined;
  location: Coordinates | undefined;
};

async function getPointPlaces(
  placeId: string,
  keywordId: string,
  { date, location }: RequestParams,
): Promise<RankedPlace[]> {
  if (getDummyPlace(placeId)) {
    return getDummyPointPlaces(placeId, keywordId, date, location);
  }
  const searchParams = new URLSearchParams();
  if (date) {
    searchParams.set("date", date);
  }
  if (location) {
    searchParams.set("longitude", location.longitude.toString());
    searchParams.set("latitude", location.latitude.toString());
  }

  const { places } = await get(
    `/places/${placeId}/keywords/${keywordId}/point?${searchParams.toString()}`,
  );
  return places;
}
export default function usePointPlaces(
  placeId: string,
  keyword: Keyword | undefined,
  params: RequestParams,
) {
  const keywordId = keyword ? keyword.id : "";
  const { data: pointPlaces, isLoading: isLoadingPointPlaces } = useQuery({
    queryKey: ["keywordDetails", placeId, keywordId, params],
    queryFn: () => getPointPlaces(placeId, keywordId, params),
    enabled: !!placeId && !!keywordId,
  });
  return { pointPlaces, isLoadingPointPlaces };
}
