import { forwardRef, ReactNode } from "react";
import {
  Button as AriaButton,
  ButtonProps as AriaButtonProps,
} from "react-aria-components";
import { tv, VariantProps } from "tailwind-variants";

export const buttonStyles = tv({
  base: "flex justify-center border items-center shrink-0 rounded-full text-base font-medium transition-[background,border,color,width,padding] [&_svg]:transition-[fill] data-[focus-visible]:outline data-[focus-visible]:outline-3",
  variants: {
    variant: {
      black: [
        "text-white [&_svg]:fill-white bg-grey-900 border-grey-900",
        "data-[hovered]:bg-grey-700",
        "data-[disabled]:border-grey-200 data-[disabled]:bg-grey-200 data-[disabled]:text-grey-400 [&_svg]:data-[disabled]:fill-grey-400",
        "data-[focus-visible]:outline-grey-900/25",
      ],
      white: [
        "bg-grey-50 border-grey-50",
        "data-[hovered]:bg-white",
        "data-[disabled]:border-grey-200 data-[disabled]:bg-grey-200 data-[disabled]:text-grey-400 [&_svg]:data-[disabled]:fill-grey-400",
        "data-[focus-visible]:outline-grey-900/25",
      ],

      green: [
        "bg-green-500 border-green-500",
        "data-[disabled]:bg-grey-200 data-[disabled]:border-grey-200 data-[disabled]:text-grey-400 [&_svg]:data-[disabled]:fill-grey-400",
        "data-[hovered]:bg-green-400",
        "data-[focus-visible]:outline-green-500/25",
      ],
      purple: [
        "text-purple-500 bg-purple-500/20 border-purple-500 [&_svg]:fill-purple-500",
        "[&_svg]:data-[disabled]:fill-grey-400 data-[disabled]:text-grey-400 data-[disabled]:bg-grey-200 data-[disabled]:border-grey-200",
        "[&_svg]:data-[hovered]:fill-white data-[hovered]:text-white data-[hovered]:bg-purple-500 ",
        "data-[focus-visible]:outline-purple-100",
      ],
      stroke: [
        "border border-grey-300 border-grey-300",
        "data-[disabled]:text-grey-400 [&_svg]:data-[disabled]:fill-grey-400 data-[disabled]:border-grey-400",
        "data-[hovered]:bg-grey-100",
        "data-[focus-visible]:outline-grey-900/25",
      ],
      transparent: [
        "border-transparent",
        "data-[disabled]:text-grey-400 [&_svg]:data-[disabled]:fill-grey-400",
        "data-[hovered]:bg-grey-100",
        "data-[focus-visible]:outline-grey-900/25",
      ],
    },
    size: {
      lg: "h-12 text-base px-6 gap-x-3.5 [&_svg]:size-4 [&_svg]:-mx-1.5",
      md: "h-11 text-sm px-5 gap-x-2.5 [&_svg]:size-3.5 [&_svg]:-mx-1",
      sm: "h-8 text-xs px-4 gap-x-2 [&_svg]:size-3 [&_svg]:-mx-1",
    },
    isIconOnly: {
      true: "justify-center p-0",
    },
  },
  compoundVariants: [
    {
      size: "sm",
      isIconOnly: true,
      class: "w-8",
    },
    {
      size: "md",
      isIconOnly: true,
      class: "w-11",
    },
    {
      size: "lg",
      isIconOnly: true,
      class: "w-12",
    },
  ],
});
export interface ButtonProps
  extends VariantProps<typeof buttonStyles>,
    Omit<AriaButtonProps, "children" | "className"> {
  className?: string;
  children: ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, className, variant, size, isIconOnly = false, ...rest },
    ref,
  ) => {
    return (
      <AriaButton
        {...rest}
        ref={ref}
        className={buttonStyles({ size, variant, className, isIconOnly })}
      >
        {children}
      </AriaButton>
    );
  },
);
export { Button };
