import usePlaces from "@/src/common/datahooks/usePlaces";
import { SelectedPlaceProvider } from "@/src/contexts";
import { getDummyPlace } from "@/src/dummyGenerator";
import DesktopNavBar from "@/src/pages/layout/DesktopNavBar.tsx";
import { createFileRoute, Outlet, useMatches } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { clsx } from "clsx";
import PlaceCard from "@/src/pages/layout/PlaceCard.tsx";
import PlaceSelection from "@/src/pages/layout/PlaceSelection.tsx";
import MobileNavBar from "@/src/pages/layout/MobileNavBar.tsx";
import { useTwBreakpoint } from "common/hooks";
import CircleInfo from "fontawesome/solid/circle-info.svg?react";
import { Button } from "common/components";
import { useIntercom } from "react-use-intercom";

export const Route = createFileRoute("/$placeId/_layout")({
  component: Layout,
  beforeLoad: ({ params }) => {
    localStorage.setItem("selectedPlaceId", params.placeId);
  },
});
function Layout() {
  const isDesktop = useTwBreakpoint("lg");
  const { update } = useIntercom();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const { placeId } = Route.useParams();
  const matches = useMatches();
  const { places } = usePlaces();

  useEffect(() => {
    if (isDesktop) {
      update({ hideDefaultLauncher: false });
      return () => update({ hideDefaultLauncher: true });
    }
  }, [isDesktop]);

  const isDemo = !!getDummyPlace(placeId);

  let selectedPlace;
  if (isDemo) {
    selectedPlace = getDummyPlace(placeId);
  } else if (places) {
    const foundPlace = places.find(({ id }) => id === placeId);
    if (foundPlace) {
      selectedPlace = foundPlace;
    }
  }

  const demoBanner = isDemo && (
    <div className="mx-auto flex items-center rounded-full border border-grey-200 bg-white px-3 py-3 shadow-lg lg:py-2">
      <CircleInfo className="size-4.5 lg:size-3" />
      <span className="ml-3 mr-4 text-xs text-grey-700 lg:text-sm">
        This is a demo. Upgrade to get higher rank out of your GMB.
      </span>
      <Button size="sm" variant="green">
        Upgrade
      </Button>
    </div>
  );

  return (
    <SelectedPlaceProvider value={selectedPlace}>
      <div className="fixed -z-base h-dvh w-full bg-grey-100 bg-[radial-gradient(57.48%_70.31%_at_50%_87.27%,#C5FCC5_0%,rgba(197,252,197,0)_100%)]" />
      <div className="flex w-full overflow-hidden">
        {isDesktop ? (
          <DesktopNavBar
            isNavExpanded={isNavExpanded}
            setIsNavExpanded={setIsNavExpanded}
          />
        ) : (
          <MobileNavBar />
        )}
        <main
          className={clsx(
            "flex w-full flex-col gap-y-2 overflow-hidden px-2 pb-24 pt-20 transition-[margin] lg:gap-y-4 lg:py-5 lg:pl-0 lg:pr-5",
            isNavExpanded ? "lg:ml-72" : "lg:ml-24",
          )}
        >
          <div className="bg-red fixed top-10"></div>
          <div className="inset-x-0 top-0 z-base flex items-center justify-between max-lg:fixed max-lg:bg-grey-100 max-lg:px-2 max-lg:py-4 lg:gap-x-8">
            <h1 className="text-2xl font-medium">
              {matches[matches.length - 1].staticData.pageTitle}
            </h1>
            {isDesktop && demoBanner}
            <PlaceSelection />
          </div>
          {!isDesktop && demoBanner}
          <PlaceCard />
          <Outlet />
        </main>
      </div>
    </SelectedPlaceProvider>
  );
}
