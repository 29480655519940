import { JSX, useState } from "react";
import { DialogTrigger } from "react-aria-components";
import { Button, Modal } from "../index.ts";
import DrivesSettings from "@/src/common/components/GridAndDrivesSettings/DrivesSettings.tsx";
import GridSettings from "@/src/common/components/GridAndDrivesSettings/GridSettings.tsx";
import { useAppStore, useTwBreakpoint } from "common/hooks";
import { useSelectedPlace } from "@/src/contexts.ts";

export default function GridAndDrivesSettings({
  renderTriggerComponent,
  initialTab,
}: {
  renderTriggerComponent: (onPress?: () => void) => JSX.Element;
  initialTab: "grid" | "drives";
}) {
  const isDesktop = useTwBreakpoint("lg");
  const { isDemoMode } = useSelectedPlace();
  const [selectedTab, setSelectedTab] = useState<"grid" | "drives">(initialTab);

  if (isDemoMode) {
    return renderTriggerComponent(() =>
      useAppStore.getState().setIsAddModalOpen(true),
    );
  }

  const tabsComponents = (
    <div className="flex gap-x-2 max-lg:justify-center max-lg:py-1.5">
      <Button
        size="sm"
        variant={selectedTab === "grid" ? "black" : "transparent"}
        onPress={() => setSelectedTab("grid")}
      >
        Grid settings
      </Button>
      <Button
        size="sm"
        variant={selectedTab === "drives" ? "black" : "transparent"}
        onPress={() => setSelectedTab("drives")}
      >
        Drives settings
      </Button>
    </div>
  );

  return (
    <DialogTrigger>
      {renderTriggerComponent()}
      <Modal
        className="flex-col gap-y-4 lg:flex-row lg:gap-x-6"
        variant="white"
      >
        {!isDesktop && tabsComponents}
        <div className="h-96 rounded-4xl bg-grey-200 lg:w-96 lg:rounded-5xl" />
        <div className="flex flex-col gap-y-6 max-lg:grow max-lg:px-3">
          {isDesktop && tabsComponents}
          {selectedTab === "drives" ? <DrivesSettings /> : <GridSettings />}
          <Button variant="purple" size="lg" className="mt-auto lg:ml-auto">
            Upgrade subscription
          </Button>
        </div>
      </Modal>
    </DialogTrigger>
  );
}
