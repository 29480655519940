import { createContext, useContext } from "react";
import { Place } from "common/types.ts";
import { getDummyPlace } from "@/src/dummyGenerator.ts";

const SelectedPlaceContext = createContext<Place | undefined>(undefined);

export const SelectedPlaceProvider = SelectedPlaceContext.Provider;
export function useSelectedPlace() {
  const selectedPlace = useContext(SelectedPlaceContext);

  if (selectedPlace === undefined) {
    throw new Error(
      "Selected place hook must be used inside a SelectedPlaceContext",
    );
  }
  return { selectedPlace, isDemoMode: !!getDummyPlace(selectedPlace.id) };
}
