import {
  Dialog,
  Popover as AriaPopover,
  PopoverProps as AriaPopoverProps,
  DialogProps as AriaDialogProps,
} from "react-aria-components";
import { clsx } from "clsx";
import { ReactNode } from "react";

interface PopoverProps extends Omit<AriaPopoverProps, "children"> {
  children: ReactNode;
}

export function Popover({ className, children, ...rest }: PopoverProps) {
  return (
    <AriaPopover
      offset={4}
      className={({ isExiting, isEntering }) =>
        clsx(
          "flex flex-col rounded-3xl bg-white shadow-lg",
          isEntering && "animate-in fade-in",
          isExiting && "animate-out fade-out",
          className,
        )
      }
      {...rest}
    >
      {children}
    </AriaPopover>
  );
}
export function PopoverDialog({ children, ...rest }: AriaDialogProps) {
  return (
    <Dialog
      className="flex flex-col gap-[inherit] overflow-hidden outline-none"
      {...rest}
    >
      {({ close }) =>
        typeof children === "function" ? children({ close }) : children
      }
    </Dialog>
  );
}
