import { createFileRoute } from "@tanstack/react-router";

import Invoices from "@/src/pages/settings/Invoices.tsx";
import Subscription from "@/src/pages/settings/Subscription.tsx";

export const Route = createFileRoute("/$placeId/_layout/settings")({
  component: Settings,
  staticData: {
    pageTitle: "Account Settings",
  },
});
function Settings() {
  const hasSubscription = false;

  return (
    <>
      <Subscription />
      {hasSubscription && <Invoices />}
    </>
  );
}
