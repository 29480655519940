import type { LabelProps as AriaLabelProps } from "react-aria-components";
import { ForwardedRef, forwardRef } from "react";
import { tv, VariantProps } from "tailwind-variants";
import { LabelContext, useContextProps } from "react-aria-components";

const labelStyle = tv({
  base: "font-medium text-grey-700 mb-1 ml-1",
  variants: {
    size: {
      sm: "text-2xs",
      md: "text-xs",
      lg: "text-sm",
    },
  },
});
export interface LabelProps
  extends Omit<AriaLabelProps, "className">,
    VariantProps<typeof labelStyle> {}
export const Label = forwardRef(
  (props: LabelProps, ref: ForwardedRef<HTMLLabelElement>) => {
    [props, ref] = useContextProps(props, ref, LabelContext);

    const { size, ...rest } = props;

    return (
      <div className={labelStyle({ size })}>
        <label {...rest} ref={ref} />
      </div>
    );
  },
);
