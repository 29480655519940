import {
  TooltipProps as AriaTooltipProps,
  Tooltip as AriaTooltip,
  TooltipTrigger as AriaTooltipTrigger,
  TooltipTriggerComponentProps as AriaTooltipTriggerProps,
  OverlayArrow,
} from "react-aria-components";
import { clsx } from "clsx";
import { ReactNode } from "react";

interface MyTooltipProps extends Omit<AriaTooltipProps, "children"> {
  children: ReactNode;
  variant: "black" | "white";
}

export function Tooltip({
  children,
  variant,
  className,
  ...props
}: MyTooltipProps) {
  return (
    <AriaTooltip
      {...props}
      offset={14}
      className={({ isExiting, isEntering }) =>
        clsx(
          "flex flex-col",
          variant === "black"
            ? "rounded-xl bg-grey-900 p-3 shadow-lg"
            : "rounded-3xl bg-white px-4 pb-5 pt-3.5 shadow-lg",
          isEntering && "animate-in fade-in",
          isExiting && "animate-out fade-out",
          className,
        )
      }
    >
      <OverlayArrow>
        {({ placement }) => {
          let rotationClassname;
          if (placement === "left") {
            rotationClassname = "-rotate-90 -translate-x-1.5";
          } else if (placement === "right") {
            rotationClassname = "rotate-90 translate-x-1.5";
          } else if (placement === "bottom") {
            rotationClassname = "rotate-180";
          }
          return (
            <svg
              className={rotationClassname}
              width="20"
              height="9"
              viewBox="0 0 20 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8649 5.13511C12.9594 7.04057 12.0067 7.99331 10.8726 8.24681C10.2979 8.37526 9.70205 8.37526 9.12743 8.24681C7.99331 7.99331 7.04057 7.04057 5.13511 5.1351L8.74228e-07 -1.74846e-06L20 0L14.8649 5.13511Z"
                fill={variant}
              />
            </svg>
          );
        }}
      </OverlayArrow>
      {children}
    </AriaTooltip>
  );
}
export function TooltipTrigger({ children, ...rest }: AriaTooltipTriggerProps) {
  return (
    <AriaTooltipTrigger delay={200} closeDelay={300} {...rest}>
      {children}
    </AriaTooltipTrigger>
  );
}
