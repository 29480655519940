import {
  Coordinates,
  DetailedPlace,
  Drive,
  GridPoint,
  Place,
  RankedPlace,
  Snapshot,
} from "common/types.ts";
import {
  fromAbsolute,
  getLocalTimeZone,
  now,
  parseDate,
  today,
} from "@internationalized/date";
import {
  gridPoints,
  randomDrives,
  competitorsPlaces,
  userPlaces,
} from "@/src/dummyData.ts";

function generateKeywordGrid(ownerPlace: Omit<Place, "subscription">) {
  return gridPoints.map(({ rank, ...rest }) => {
    const places: ({ rank: number | null; owner?: boolean } & RankedPlace)[] = [
      { rank, owner: true, ...ownerPlace, average_rank: 0, categories: [] },
    ];

    //add 5 - 10 random places to the grid point
    for (let i = 0; i < Math.round(Math.random() * 15) + 5; i += 1) {
      const randomRank = Math.round(Math.random() * 40);
      let randomPlace: (typeof competitorsPlaces)[number];
      do {
        randomPlace =
          competitorsPlaces[
            Math.round(Math.random() * (competitorsPlaces.length - 1))
          ];
      } while (places.some(({ name }) => name === randomPlace.name));

      places.push({
        ...randomPlace,
        rank: randomRank > 20 ? null : randomRank,
        average_rank: null,
        id: window.crypto.randomUUID(),
        address: "",
      });
    }
    return {
      ...rest,
      rank,
      places,
    };
  });
}
const gridSize = 7;
function generateSnapshots(place: Omit<Place, "subscription">) {
  const snapshots: (Snapshot & {
    grid: ReturnType<typeof generateKeywordGrid>;
  })[] = [];
  //i: the number of dates that will be generated
  for (let i = 0; i < 46; i += 1) {
    const prevSnapshot = snapshots[i - 1];

    const grid = generateKeywordGrid(place);

    const placesData: Record<string, { totalRanking: number; count: number }> =
      {};

    grid.forEach(({ places }) => {
      places.forEach(({ rank, name }) => {
        if (rank) {
          if (placesData[name]) {
            placesData[name].totalRanking += rank;
            placesData[name].count++;
          } else {
            placesData[name] = { totalRanking: rank, count: 1 };
          }
        }
      });
    });
    grid.forEach(({ places }) => {
      places.forEach((place) => {
        if (placesData[place.name]) {
          const averageRank =
            placesData[place.name].totalRanking / placesData[place.name].count;
          place.average_rank = Math.round(averageRank * 10) / 10;
        }
      });
    });

    snapshots.push({
      date: prevSnapshot
        ? parseDate(prevSnapshot.date).add({ days: -1 }).toString()
        : today(getLocalTimeZone()).toString(),
      grid_size: gridSize,
      grid,
      difficulty: prevSnapshot
        ? prevSnapshot.difficulty + Math.round(Math.random() * 6) - 3
        : Math.round(Math.random() * 100),
      search_volume: prevSnapshot
        ? prevSnapshot.search_volume + Math.round(Math.random() * 6) - 3
        : Math.round(Math.random() * 10000),
      average_rank: Math.round(
        grid.reduce((accumulator, { rank }) => {
          return rank ? rank + accumulator : accumulator;
        }, 0) / grid.length,
      ),
      preview: grid.map(({ rank }) => rank),
    });
  }
  return snapshots;
}
function generateKeywords(
  place: Omit<Place, "subscription">,
  keywordLabels: string[],
) {
  const keywords: Record<
    string,
    { label: string; snapshots: ReturnType<typeof generateSnapshots> }
  > = {};
  for (let i = 0; i < keywordLabels.length; i++) {
    const snapshots = generateSnapshots(place);
    keywords[keywordLabels[i].replaceAll(" ", "").toLowerCase()] = {
      label: keywordLabels[i],
      snapshots,
    };
  }
  return keywords;
}
function generateDrives() {
  const drives: Drive[] = [];

  for (let i = 0; i < 30; i += 1) {
    const prevDrive = drives[i - 1];
    const { image_url, steps } =
      randomDrives[Math.round(Math.random() * (randomDrives.length - 1))];
    drives.push({
      id: i + 1,
      steps,
      image_url,
      created_at: prevDrive
        ? fromAbsolute(prevDrive.created_at, getLocalTimeZone())
            .subtract({ hours: 1 })
            .toDate()
            .getTime()
        : now(getLocalTimeZone()).set({ minute: 0 }).toDate().getTime(),
    });
  }
  return drives;
}
export const dummyDrives = generateDrives();
export const dummyPlaces: (Omit<DetailedPlace, "keywords"> & {
  keywords: ReturnType<typeof generateKeywords>;
})[] = userPlaces.map((place) => ({
  ...place,
  subscription: {
    id: window.crypto.randomUUID(),
    item: { external_name: "Test", max_keywords: 20 },
    status: "active",
  },
  keywords: generateKeywords(place, [
    "Springfield bar and grill",
    "Springfield bar specials",
    "Happy hour Springfield",
    "Best bar in Springfield",
    "Local taverns near me",
  ]),
}));
export function getDummyPlace(placeId: string): DetailedPlace | undefined {
  const place = dummyPlaces.find(({ id }) => id === placeId);
  if (place) {
    return {
      ...place,
      keywords: Object.entries(place.keywords).map(
        ([id, { label, snapshots }]) => ({
          id,
          label,
          difficulty: snapshots[0].difficulty,
          search_volume: snapshots[0].search_volume,
          state: "ready",
          grid_state: "ready",
        }),
      ),
    };
  }
}
export function getSnapshots(placeId: string, keywordId: string): Snapshot[] {
  const place = dummyPlaces.find(({ id }) => id === placeId);
  if (place) {
    return place.keywords[keywordId].snapshots;
  }
  return [];
}
export function getKeywordDetails(
  placeId: string,
  keywordId: string,
  snapshotDate: string | undefined,
): {
  grid: GridPoint[];
  places: RankedPlace[];
} {
  const place = dummyPlaces.find(({ id }) => id === placeId);
  let grid: GridPoint[] = [];
  const allPlaces: RankedPlace[] = [];

  if (place) {
    const snapshot = place.keywords[keywordId].snapshots.find(
      ({ date }) => date === snapshotDate,
    );
    if (snapshot) {
      grid = snapshot.grid.map(({ places, ...rest }) => {
        places.forEach((place) => {
          if (
            !place.owner &&
            !allPlaces.find(({ name }) => name === place.name)
          ) {
            allPlaces.push(place);
          }
        });

        const finalPlaces = places.slice(0, 3);

        finalPlaces.sort((a, b) => {
          if (a.rank === null) {
            return 1;
          } else if (b.rank === null) {
            return -1;
          } else {
            return a.rank - b.rank;
          }
        });
        return {
          ...rest,
          places: finalPlaces,
        };
      });
    }
  }
  return { grid, places: allPlaces };
}
export function getDummyPointPlaces(
  placeId: string,
  keywordId: string,
  snapshotDate: string | undefined,
  location: Coordinates | undefined,
): RankedPlace[] {
  const place = dummyPlaces.find(({ id }) => id === placeId);

  if (place) {
    const snapshot = place.keywords[keywordId].snapshots.find(
      ({ date }) => date === snapshotDate,
    );
    if (snapshot && location) {
      const gridPoint = snapshot.grid.find(
        ({ latitude, longitude }) =>
          latitude === location.latitude && longitude === location.longitude,
      );
      if (gridPoint) {
        return gridPoint.places.filter(({ owner }) => !owner);
      }
    }
  }
  return [];
}
