import { Button, Label, Slider, Select } from "../index.ts";
import { WEEK_DAYS } from "@/src/constants.tsx";

export default function GridSettings() {
  return (
    <>
      <Select
        label="Grid size"
        size="lg"
        className="w-full"
        placeholder="Select a grid size"
      />
      <div className="flex flex-col">
        <Label size="lg">Frequency</Label>
        <div className="flex gap-x-1.5">
          {WEEK_DAYS.map((weekDay) => (
            <Button variant="stroke" size="md" isIconOnly>
              {weekDay.slice(0, 3)}
            </Button>
          ))}
        </div>
      </div>
      <Slider className="w-full" maxValue={20} step={5}>
        <Label size="lg">Grid distance</Label>
      </Slider>
    </>
  );
}
