import {
  type SliderProps as AriaSliderProps,
  Slider as AriaSlider,
  SliderThumb,
  SliderTrack,
} from "react-aria-components";
import { ReactNode, JSX } from "react";

interface SliderProps<T> extends Omit<AriaSliderProps<T>, "children"> {
  children?: ReactNode;
}

export function Slider<T extends number | number[]>({
  children,
  ...props
}: SliderProps<T>) {
  return (
    <AriaSlider {...props}>
      {children}
      <SliderTrack className="flex h-3 items-center">
        {({ state }) => {
          const stepMarkComponents: JSX.Element[] = [];
          if (props.step && props.maxValue) {
            for (
              let i = state.getThumbPercent(0) * props.maxValue;
              i <= props.maxValue;
              i += props.step
            ) {
              stepMarkComponents.push(
                <div
                  className="absolute size-2.5 rounded-full bg-grey-200"
                  style={{ left: `${(i / props.maxValue) * 100}%` }}
                />,
              );
            }
          }
          return (
            <>
              <div className="h-0.5 w-full rounded-full bg-grey-200" />
              <div className="absolute inset-x-0 -ml-1.5 mr-1.5 flex items-center">
                {stepMarkComponents}
              </div>
              <div
                className="absolute h-1 rounded-full bg-grey-900"
                style={{ width: state.getThumbPercent(0) * 100 + "%" }}
              />
              {state.values.map((_, i) => (
                <SliderThumb
                  className="top-1/2 size-3 rounded-full bg-grey-900"
                  key={i}
                  index={i}
                />
              ))}
            </>
          );
        }}
      </SliderTrack>
    </AriaSlider>
  );
}
