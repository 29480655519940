import { Coordinates } from "common/types.ts";

export function getQueryKeys() {
  const queryKeys = {
    places: {
      base: ["places"],
      list: () => [...queryKeys.places.base, "list"],
      details: (placeId: string) => [
        ...queryKeys.places.base,
        placeId,
        "details",
      ],
    },
    keywords: {
      base: (placeId: string) => [placeId, "keywords"],
      details: (placeId: string, keywordId: string, date: string) => [
        ...queryKeys.keywords.base(placeId),
        "details",
        keywordId,
        date,
      ],
      snapshots: (
        placeId: string,
        keywordId: string,
        params: { page: number; pageSize: number },
      ) => [
        ...queryKeys.keywords.base(placeId),
        "snapshots",
        keywordId,
        params,
      ],
      point: (
        placeId: string,
        keywordId: string,
        params: { date: string } & Coordinates,
      ) => [...queryKeys.keywords.base(placeId), "point", keywordId, params],
      suggestions: (placeId: string) => [
        ...queryKeys.keywords.base(placeId),
        "",
      ],
    },
    reviews: {
      base: ["reviews"],
      list: (placeId: string) => [...queryKeys.places.base, placeId],
    },
    paymentSources: ["paymentSources"],
  };
  return queryKeys;
}
