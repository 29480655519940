import { tv, VariantProps } from "tailwind-variants";
import { clsx } from "clsx";
import { getNumberFromString } from "common/utils.ts";

const colorClassnames = [
  "bg-red-400",
  "bg-red-700",
  "bg-red-200",
  "bg-yellow-100",
  "bg-yellow-200",
  "bg-yellow-300",
  "bg-yellow-400",
  "bg-yellow-500",
  "bg-green-100",
  "bg-green-400",
  "bg-green-800",
  "bg-purple-100",
  "bg-purple-200",
  "bg-purple-400",
  "bg-purple-500",
  "bg-purple-600",
  "bg-purple-800",
  "bg-grey-200",
];
export const avatarStyles = tv({
  base: "flex items-center shrink-0 justify-center font-medium text-white rounded-full uppercase",
  variants: {
    size: {
      lg: "size-12 text-sm",
      sm: "size-8 text-xs",
    },
  },
});
export function PlaceAvatar({
  size,
  placeName,
}: { placeName: string } & VariantProps<typeof avatarStyles>) {
  const placeNameComponents = placeName.split(" ");
  const label =
    placeNameComponents.length > 1
      ? `${placeNameComponents[0][0]}${placeNameComponents[1][0]}`
      : placeName.slice(0, 2);

  return (
    <div
      className={clsx(
        avatarStyles({ size }),
        colorClassnames[
          getNumberFromString(placeName) % colorClassnames.length
        ],
      )}
    >
      {label}
    </div>
  );
}
