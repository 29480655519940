import { useSelectedPlace } from "@/src/contexts.ts";

import LocationDotItem from "fontawesome/solid/location-dot.svg?react";
import BadgeCheck from "fontawesome/solid/badge-check.svg?react";

import { Badge, RatingStars } from "@/src/common/components";
import { useTwBreakpoint } from "common/hooks";

export default function PlaceCard() {
  const { selectedPlace, isDemoMode } = useSelectedPlace();
  const isDesktop = useTwBreakpoint("lg");
  const { rating, rating_count, address, name, primary_category } =
    selectedPlace;

  return (
    <div className="flex flex-col gap-y-2 rounded-4xl bg-white p-3 max-lg:items-start lg:gap-y-3 lg:rounded-6xl lg:bg-grey-50 lg:p-6">
      <div className="flex flex-col items-start max-lg:gap-y-2 lg:flex-row-reverse lg:justify-between">
        <div className="flex items-center gap-x-2">
          <span className="text-base font-semibold text-grey-900">
            {rating}
          </span>
          <RatingStars rating={rating} />
          <span className="text-base font-normal text-grey-500">
            ({rating_count})
          </span>
        </div>
        <div className="flex items-center gap-x-1">
          <h2 className="text-lg font-semibold lg:text-xl">{name}</h2>
          {isDemoMode && (
            <Badge variant="greenFull" size="md">
              Demo
            </Badge>
          )}
        </div>
      </div>
      <div className="flex items-center gap-2">
        {primary_category && (
          <Badge variant={isDesktop ? "white" : "stroke"} size="md">
            <BadgeCheck />
            {primary_category}
          </Badge>
        )}
        <Badge variant={isDesktop ? "white" : "stroke"} size="md">
          <LocationDotItem />
          {address}
        </Badge>
      </div>
    </div>
  );
}
