import { createLink } from "@tanstack/react-router";
import {
  useObjectRef,
  useHover,
  useLink,
  useFocusRing,
  mergeProps,
} from "react-aria";
import { ButtonProps, buttonStyles } from "common/components";
import { forwardRef } from "react";
import { Link } from "react-aria-components";
import { ButtonProps as AriaButtonProps } from "react-aria-components";
export const RouterLink = createLink(Link);

interface ButtonComponentProps
  extends Omit<
    ButtonProps,
    | keyof Omit<AriaButtonProps, "children" | "className">
    | "leftIcon"
    | "rightIcon"
  > {}

const ButtonComponent = forwardRef<HTMLAnchorElement, ButtonComponentProps>(
  (
    { variant, size, isIconOnly, className, children, ...rest },
    forwardedRef,
  ) => {
    const ref = useObjectRef(forwardedRef);
    const { isHovered, hoverProps } = useHover(rest);
    const { isPressed, linkProps } = useLink(rest, ref);
    const { isFocusVisible, isFocused, focusProps } = useFocusRing(rest);

    return (
      <a
        {...mergeProps(linkProps, hoverProps, focusProps, rest)}
        className={buttonStyles({ size, variant, isIconOnly, className })}
        ref={ref}
        data-hovered={isHovered || undefined}
        data-pressed={isPressed || undefined}
        data-focus-visible={isFocusVisible || undefined}
        data-focused={isFocused || undefined}
      >
        {children}
      </a>
    );
  },
);
export const ButtonLink = createLink(ButtonComponent);
