import SettingsItem from "@/src/pages/account/SettingsItem.tsx";
import useCustomer from "@/src/common/datahooks/useCustomer.ts";
import { Input } from "@/src/common/components";

export default function FullName({
  isEditing,
  toggleIsEditing,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
}) {
  const { customer } = useCustomer();

  return (
    <SettingsItem
      isEditing={isEditing}
      toggleIsEditing={toggleIsEditing}
      label="Full Name"
      onSubmit={() => {}}
      editingComponent={
        customer && (
          <Input
            size="md"
            value={`${customer.first_name} ${customer.last_name}`}
            className="w-full md:min-w-72"
            autoFocus
          />
        )
      }
    >
      {customer ? (
        <span className="text-sm font-medium">
          {customer.first_name} {customer.last_name}
        </span>
      ) : (
        <span className="text-sm">Loading...</span>
      )}
    </SettingsItem>
  );
}
