import {
  type CheckboxProps,
  Checkbox as AriaCheckbox,
} from "react-aria-components";
import { clsx } from "clsx";
import Check from "fontawesome/solid/check.svg?react";
import Minus from "fontawesome/solid/minus.svg?react";

export function Checkbox({ children, className, ...props }: CheckboxProps) {
  return (
    <AriaCheckbox
      className={clsx("group flex gap-x-1.5 text-sm text-grey-700", className)}
      {...props}
    >
      {({ isIndeterminate }) => (
        <>
          <div
            className={clsx(children && "mt-[2px]", [
              "size-4 rounded-md border-2 border-grey-900",
              "group-data-[hovered]:border-grey-700",
              "group-data-[focused]:outline group-data-[focused]:outline-3 group-data-[focused]:outline-grey-900/25",
              "group-data-[selected]:bg-grey-900 group-data-[selected]:group-data-[hovered]:bg-grey-700",
              "group-data-[disabled]:border-grey-300 group-data-[disabled]:group-data-[selected]:bg-grey-300 [&_svg]:group-data-[disabled]:group-data-[selected]:fill-grey-400",
            ])}
          >
            {isIndeterminate ? (
              <Minus className="size-3 fill-white" />
            ) : (
              <Check className="size-3 fill-white" />
            )}
          </div>
          {children}
        </>
      )}
    </AriaCheckbox>
  );
}
