import { useState } from "react";
import { useTwBreakpoint } from "common/hooks";
import ErrorDialog from "@/src/pages/home/ErrorDialog.tsx";
import GridStats from "@/src/pages/home/GridStats.tsx";
import PlacesList from "@/src/pages/home/PlacesList/PlacesList.tsx";
import GridMap from "@/src/pages/home/GridMap/GridMap.tsx";
import SnapshotStats from "@/src/pages/home/SnapshotStats.tsx";
import useKeywordDetails from "common/datahooks/useKeywordDetails.tsx";
import { useParams, useSearch } from "@tanstack/react-router";
import { Coordinates, Keyword, Snapshot } from "common/types.ts";
import { clsx } from "clsx";
import usePointPlaces from "common/datahooks/usePointPlaces.tsx";

export default function GridArea({
  selectedKeyword,
  snapshots,
}: {
  selectedKeyword: Keyword | undefined;
  snapshots: Snapshot[] | undefined;
}) {
  const isDesktop = useTwBreakpoint("lg");
  const { placeId } = useParams({ from: "/$placeId" });
  const { selectedDate, compareWithDate } = useSearch({
    from: "/$placeId/_layout/home",
  });
  const { keywordDetails, keywordDetailsError, refetchKeywordDetails } =
    useKeywordDetails(placeId, selectedKeyword, selectedDate);
  const [selectedPoint, setSelectedPoint] = useState<Coordinates>();

  const { pointPlaces } = usePointPlaces(placeId, selectedKeyword, {
    date: selectedDate,
    location: selectedPoint,
  });

  const gridStatsComponent = (
    <GridStats gridPoints={keywordDetails && keywordDetails.grid} />
  );
  const listComponent = (
    <div
      className={clsx(
        "flex flex-col rounded-4xl border-2 bg-white p-3 lg:z-base lg:basis-2/5 lg:rounded-5xl lg:p-5",
        selectedPoint ? "border-grey-900" : "border-transparent",
      )}
    >
      <PlacesList
        places={
          selectedPoint ? pointPlaces : keywordDetails && keywordDetails.places
        }
      />
    </div>
  );
  const gridMapComponent = (
    <GridMap
      gridPoints={keywordDetails && keywordDetails.grid}
      setSelectedPoint={setSelectedPoint}
      selectedPoint={selectedPoint}
    />
  );
  const snapshotStatsComponent = (
    <SnapshotStats
      selectedSnapshot={
        snapshots &&
        snapshots.find((snapshot) => snapshot.date === selectedDate)
      }
      compareWithSnapshot={
        snapshots &&
        snapshots.find((snapshot) => snapshot.date === compareWithDate)
      }
    />
  );

  if (isDesktop) {
    return (
      <div className="relative mt-4 flex h-[915px] flex-1 gap-x-2 overflow-hidden p-2">
        {gridMapComponent}
        <div className="pointer-events-none z-base flex flex-1 basis-3/5 flex-col justify-between">
          {snapshotStatsComponent}
          {gridStatsComponent}
          {/*<div className="flex flex-1 items-center justify-center">
              {!true && (
                <div className="grid grid-cols-7 grid-rows-7 gap-4">
                  {new Array(7 * 7).fill(" ").map((_, index) => {
                    return (
                      <div
                        className="size-8 h-2 w-2 animate-pulse rounded-full bg-grey-300 lg:size-12"
                        key={index}
                      />
                    );
                  })}
                </div>
              )}
            </div>*/}
        </div>
        {listComponent}
        <ErrorDialog
          isOpen={!!keywordDetailsError}
          onRetry={refetchKeywordDetails}
        />
      </div>
    );
  } else {
    return (
      <>
        <div className="flex flex-col rounded-4xl bg-white px-3 pb-3">
          {snapshotStatsComponent}
          <div className="relative flex h-96 w-full items-end justify-center">
            {gridMapComponent}
            <div className="z-base mb-2">{gridStatsComponent}</div>
          </div>
        </div>
        {listComponent}
      </>
    );
  }
}
