import { Button } from "react-aria-components";
import { buttonStyles, Pagination } from "common/components";

import ArrowUpRight from "fontawesome/regular/arrow-up-right.svg?react";
import NoInvoicesImage from "@/src/assets/empty-placeholder/no-invoices.svg?react";

import { usePagination, useTwBreakpoint } from "common/hooks";
import useDrives from "../datahooks/useDrives.tsx";
import { getRouteApi } from "@tanstack/react-router";
import EmptyPlaceholder from "common/components/EmptyPlaceholder/EmptyPlaceholder.tsx";
import DriveDetails from "./DriveDetails.tsx";
import { fromAbsolute, getLocalTimeZone } from "@internationalized/date";
import { addLeadingZero, getWeekDay } from "common/utils.ts";

const { useParams } = getRouteApi("/$placeId");

export default function AllDrives() {
  const isDesktop = useTwBreakpoint("lg");
  const { placeId } = useParams();
  const { drives } = useDrives(placeId);
  const { paginationComponentProps, pageItems, isPaginationVisible } =
    usePagination(isDesktop ? 20 : 6, drives);

  if (drives && !drives.length) {
    return (
      <EmptyPlaceholder
        title="There are no drives yet"
        subtitle="Lorem ipsum dolor sit amet consectetur. Etiam suscipit sit diam in"
        image={<NoInvoicesImage />}
      />
    );
  }
  return (
    <div className="flex grow flex-col rounded-4xl bg-white p-3 pb-4 lg:rounded-7xl lg:p-6">
      <div className="mb-6 grid grid-cols-1 gap-2 md:grid-cols-2 lg:mb-20 lg:grid-cols-3 xl:grid-cols-4">
        {pageItems.map((drive) => {
          const { id, image_url, created_at } = drive;
          const dateTime = fromAbsolute(created_at, getLocalTimeZone());

          return (
            <DriveDetails drive={drive} key={id}>
              <Button className="group flex items-center rounded-3xl border border-grey-300 pr-3 data-[focused]:border-grey-400 lg:pr-4">
                {({ isHovered, isFocused }) => {
                  return (
                    <>
                      <img
                        className="mr-3 size-16 rounded-3xl bg-grey-200 lg:mr-4 lg:size-20"
                        src={image_url}
                        alt=""
                      />
                      <div className="flex flex-1 flex-col gap-y-0.5 overflow-hidden py-2">
                        <span className="truncate text-left text-sm font-normal text-grey-500">
                          {getWeekDay(dateTime).slice(0, 3)} {dateTime.day} |{" "}
                          {addLeadingZero(dateTime.hour)}:
                          {addLeadingZero(dateTime.minute)}
                        </span>
                        <span className="truncate text-left text-base font-semibold lg:text-xl">
                          Drive {id}
                        </span>
                      </div>
                      {(isHovered || isFocused) && (
                        <div
                          className={buttonStyles({
                            isIconOnly: true,
                            variant: "stroke",
                            size: "lg",
                            className: "ml-3 max-lg:hidden",
                          })}
                        >
                          <ArrowUpRight />
                        </div>
                      )}
                    </>
                  );
                }}
              </Button>
            </DriveDetails>
          );
        })}
      </div>
      {isPaginationVisible && (
        <Pagination
          {...paginationComponentProps}
          className="mx-auto mt-auto"
          size="md"
        />
      )}
    </div>
  );
}
