import { useState } from "react";
import { Keyword } from "common/types.ts";
import { Button, Input, Modal } from "common/components";
import MagnifyingGlass from "fontawesome/solid/magnifying-glass.svg?react";
import ViewKeywords from "./ViewKeywords.tsx";
import AddKeywords from "@/src/pages/home/KeywordsModal/AddKeywords.tsx";

export default function KeywordsModal({
  isOpen,
  setIsOpen,
  keywords,
  maxKeywords,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  keywords: Keyword[];
  maxKeywords: number;
}) {
  const [searchText, setSearchText] = useState("");
  const [selectedTab, setSelectedTab] = useState<"view" | "add">("view");

  const filteredKeywords = keywords.filter(({ label }) => {
    return searchText ? label.includes(searchText) : true;
  });

  return (
    <Modal
      variant="grey"
      className="flex-col lg:w-[1000px] lg:pb-8"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      {({ close }) => (
        <>
          <div className="mb-5 flex gap-x-3">
            <Button
              variant={selectedTab === "view" ? "black" : "transparent"}
              onPress={() => setSelectedTab("view")}
              size="lg"
            >
              My keywords
            </Button>
            <Button
              variant={selectedTab === "add" ? "black" : "transparent"}
              onPress={() => setSelectedTab("add")}
              size="lg"
            >
              Add new keywords
            </Button>
          </div>
          <div className="mb-5 flex items-center justify-between">
            <Input
              leftIcon={<MagnifyingGlass />}
              size="lg"
              value={searchText}
              onChange={({ target: { value } }) => setSearchText(value)}
              placeholder="Search keyword"
            />
            <div className="flex items-center">
              <span className="mr-0.5 text-xs font-medium text-grey-900">
                {maxKeywords - keywords.length}
              </span>
              <span className="mr-4 text-xs text-grey-600">
                /{maxKeywords} left
              </span>
              <Button variant="purple" size="sm">
                Upgrade
              </Button>
            </div>
          </div>
          <div className="-mx-6 -mb-8 rounded-7xl bg-white px-3 pt-6">
            {selectedTab === "add" ? (
              <AddKeywords
                searchText={searchText}
                keywords={keywords}
                maxKeywords={maxKeywords}
              />
            ) : (
              <ViewKeywords keywords={filteredKeywords} closeModal={close} />
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
