import SettingsItem from "@/src/pages/account/SettingsItem.tsx";
import { Input } from "@/src/common/components";

export default function Password({
  isEditing,
  toggleIsEditing,
}: {
  isEditing: boolean;
  toggleIsEditing: () => void;
}) {
  /*function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const data = Object.fromEntries(new FormData(e.currentTarget));
    const { password, confirmPassword } = data;
    if (password === confirmPassword && typeof password === "string") {
      updatePassword({ password: password }).then(() => {});
    } else {
    }
  }*/

  return (
    <SettingsItem
      label="Password"
      onSubmit={() => {}}
      isEditing={isEditing}
      toggleIsEditing={toggleIsEditing}
      editingComponent={
        <Input
          size="md"
          type="password"
          placeholder="New password"
          className="w-full md:min-w-72"
          autoFocus
        />
      }
    >
      <span className="text-sm font-medium">●●●●●●●●</span>
    </SettingsItem>
  );
}
