import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import { Coordinates, type GridPoint as GridPointType } from "common/types.ts";
import { useSelectedPlace } from "@/src/contexts.ts";
import GridPoint from "./GridPoint.tsx";
import { useTwBreakpoint } from "common/hooks";

export default function GridMap({
  gridPoints,
  selectedPoint,
  setSelectedPoint,
}: {
  gridPoints: GridPointType[] | undefined;
  selectedPoint: Coordinates | undefined;
  setSelectedPoint: (point: Coordinates | undefined) => void;
}) {
  const { selectedPlace } = useSelectedPlace();
  const isDesktop = useTwBreakpoint("lg");
  const [map, setMap] = useState<mapboxgl.Map>();
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const defaultZoom = isDesktop ? 14.4 : 14.2;

  useEffect(() => {
    mapboxgl.accessToken =
      "pk.eyJ1Ijoibmlrb2xhY2ljbWlsIiwiYSI6ImNsdnA5NWcxZDByNmgycW82eHEwYXk2bzAifQ.5B3djX0vly6PJB30RePMMA";

    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v12",
      zoom: defaultZoom,
      maxZoom: 15.5,
      minZoom: defaultZoom,
      cooperativeGestures: true,
      pitchWithRotate: false,
      dragRotate: false,
      touchZoomRotate: false,
    });

    setMap(map);

    const updatePadding = () => {
      if (mapContainerRef.current) {
        const hasPadding = window.innerWidth >= 1024;
        map.setPadding({
          top: hasPadding ? mapContainerRef.current.clientHeight / 10 : 0,
          bottom: 0,
          left: 0,
          right: hasPadding ? mapContainerRef.current.clientWidth / 2.5 : 0,
        });
      }
    };
    updatePadding();

    window.addEventListener("resize", updatePadding);
    return () => {
      map.remove();
      window.removeEventListener("resize", updatePadding);
    };
  }, []);

  useEffect(() => {
    if (selectedPlace && map) {
      const { longitude, latitude } = selectedPlace.location;

      map.setCenter({ lon: longitude, lat: latitude });
      map.setZoom(defaultZoom);
      /*mapRef.current.setMaxBounds([
        [longitude - 0.025, latitude - 0.025],
        [longitude + 0.025, latitude + 0.025],
      ]);*/
    }
  }, [selectedPlace, map]);

  return (
    <div className="absolute inset-0 size-full" ref={mapContainerRef}>
      <div id="map" className="size-full rounded-xl lg:rounded-6xl"></div>
      {gridPoints &&
        map &&
        gridPoints.map((point) => {
          const { latitude, longitude } = point;
          const isSelected = selectedPoint
            ? latitude === selectedPoint.latitude &&
              longitude === selectedPoint.longitude
            : undefined;
          const markerCoords = {
            lng: longitude,
            lat: latitude,
          };
          return (
            <GridPoint
              key={JSON.stringify(markerCoords)}
              point={point}
              map={map}
              onClick={() =>
                setSelectedPoint(
                  isSelected ? undefined : { longitude, latitude },
                )
              }
              isSelected={isSelected}
            />
          );
        })}
    </div>
  );
}
