import { Modal as AriaModal, Dialog } from "react-aria-components";
import { clsx } from "clsx";
import { ModalOverlay } from "common/components";
import { ReactNode, useEffect, useRef } from "react";

interface MobileSheetProps {
  children: (({ close }: { close: () => void }) => ReactNode) | ReactNode;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onAfterClose?: () => void;
}
export function MobileSheet({
  children,
  isOpen,
  setIsOpen,
  onAfterClose,
}: MobileSheetProps) {
  const timeoutRef = useRef<number>();

  useEffect(() => {
    if (!isOpen) {
      if (onAfterClose) {
        timeoutRef.current = window.setTimeout(onAfterClose, 300);
        return () => clearTimeout(timeoutRef.current);
      }
    } else {
      clearTimeout(timeoutRef.current);
    }
  }, [isOpen, onAfterClose]);

  return (
    <ModalOverlay isDismissable isOpen={isOpen} onOpenChange={setIsOpen}>
      <AriaModal
        className={({ isExiting, isEntering }) =>
          clsx(
            "fixed inset-x-0 bottom-0",
            isEntering && "animate-in slide-in-from-bottom",
            isExiting && "animate-out slide-out-to-bottom",
          )
        }
      >
        <div className="mx-auto mb-1.5 h-1 w-12 rounded-full bg-white" />
        <Dialog className="flex flex-col rounded-t-6xl bg-white px-5 py-6 outline-none">
          {children}
        </Dialog>
      </AriaModal>
    </ModalOverlay>
  );
}
