export const randomDrives = [
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/1fbc2b1c-06c7-44c1-8d5d-7a5400896ca3-heyrank",
    steps: [
      {
        timestamp: 1730211959000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730212049000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730212062000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730212087000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/8332f3e2-2784-4cbc-95cd-7a234bb467b0-heyrank",
    steps: [
      {
        timestamp: 1730210051000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730210141000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730210154000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730210179000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/98df1acf-5934-433b-8715-f4edfbd80652-heyrank",
    steps: [
      {
        timestamp: 1730211851000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730211941000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730211954000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730211979000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/dbcc6515-6a22-4f65-aea1-7d0c07831db7-heyrank",
    steps: [
      {
        timestamp: 1730209079000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730209169000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730209182000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730209207000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/36758338-5ba3-46c2-b442-1dd8c0b711e6-heyrank",
    steps: [
      {
        timestamp: 1730208827000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730208917000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730208930000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730208955000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/c34c5531-1ac7-4d55-b2dd-8c65c734d353-heyrank",
    steps: [
      {
        timestamp: 1730209691000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730209781000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730209794000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730209819000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/b25d1bd1-e872-4668-96f3-f2d1a027a954-heyrank",
    steps: [
      {
        timestamp: 1730209259000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730209349000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730209362000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730209387000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/a365130c-a7e6-4da5-aa29-662bb6786228-heyrank",
    steps: [
      {
        timestamp: 1730208683000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730208773000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730208786000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730208811000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/200e663a-f015-4ace-9b9c-6d5d77216ee9-heyrank",
    steps: [
      {
        timestamp: 1730211203000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730211293000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730211306000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730211331000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/308b017f-144e-440f-a8c1-402018b7f32b-heyrank",
    steps: [
      {
        timestamp: 1730209367000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730209457000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730209470000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730209495000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/2e7dfded-56be-4394-ad57-da3944a59c74-heyrank",
    steps: [
      {
        timestamp: 1730211996000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730212086000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730212099000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730212124000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/d6c3a76b-36f6-4abf-a20b-e23e3db000c3-heyrank",
    steps: [
      {
        timestamp: 1730210124000,
        instructions:
          "Head southwest on Av. du Gâtinais/D2460 toward Rue de la ToucheContinue to follow D2460",
      },
      {
        timestamp: 1730210214000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730210227000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730210252000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/f8c5b0aa-61b8-4c6c-8c0d-8a365f68cc9c-heyrank",
    steps: [
      {
        timestamp: 1730210912000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730211016000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730211025000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730211047000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/4b90b4f3-ef17-4466-b205-a729f7b1e74d-heyrank",
    steps: [
      {
        timestamp: 1730213180000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730213284000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730213293000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730213315000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/81433c65-4245-4a9a-8e97-d9bcc5ae0425-heyrank",
    steps: [
      {
        timestamp: 1730212209000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730212313000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730212322000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730212344000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/e57c174d-3f08-4a91-a200-5fc51714d575-heyrank",
    steps: [
      {
        timestamp: 1730212857000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730212961000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730212970000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730212992000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/2d674e75-7880-45fb-b253-ad806b133b69-heyrank",
    steps: [
      {
        timestamp: 1730212461000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730212565000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730212574000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730212596000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/d20fccfa-59f1-4da8-ab36-fef4698f1c10-heyrank",
    steps: [
      {
        timestamp: 1730211237000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730211341000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730211350000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730211372000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/64f3c70f-86a0-433d-9818-9ecfee6edb5c-heyrank",
    steps: [
      {
        timestamp: 1730212713000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730212817000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730212826000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730212848000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/cece57c2-733d-4037-8b81-662bb4b2212c-heyrank",
    steps: [
      {
        timestamp: 1730210517000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730210621000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730210630000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730210652000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/d409dd9e-7ee2-46c8-b880-0c05954df217-heyrank",
    steps: [
      {
        timestamp: 1730210769000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730210873000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730210882000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730210904000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/0ca0c2ed-b1d7-4216-b47a-da8a42fcebca-heyrank",
    steps: [
      {
        timestamp: 1730211993000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730212097000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730212106000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730212128000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/b5bcfa42-059b-48fb-8f2b-c7b32ebf86ed-heyrank",
    steps: [
      {
        timestamp: 1730213217000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730213321000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730213330000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730213352000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/9a171203-f84d-447b-80b5-caea81facd15-heyrank",
    steps: [
      {
        timestamp: 1730213505000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730213609000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730213618000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730213640000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/659d4ef4-5591-46d5-a49b-210f5d4d7ad2-heyrank",
    steps: [
      {
        timestamp: 1730287253000,
        instructions: "Head east on Rue des Guerines toward Rue du Gouy",
      },
      {
        timestamp: 1730287291000,
        instructions:
          "Turn right onto Av. du Gâtinais/D2460Continue to follow D2460",
      },
      {
        timestamp: 1730287373000,
        instructions:
          "Turn left onto Pl. Aristide Briand/D962 (signs for Centre Ville)",
      },
      {
        timestamp: 1730287386000,
        instructions:
          "At the roundabout, continue straight onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730287411000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/7d6c0ed1-ad1d-43dc-8c1f-9f36e3f24d1c-heyrank",
    steps: [
      {
        timestamp: 1730211489000,
        instructions:
          "Head northeast on Rte d'Orléans/D960 toward Rue de la Croix Saint-JacquesContinue to follow D960",
      },
      {
        timestamp: 1730211593000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730211602000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730211624000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/b2cc2ed2-c202-4fbb-8f02-2ff1ebf6550d-heyrank",
    steps: [
      {
        timestamp: 1730214674000,
        instructions:
          "Head southeast on Allée de l'Auvernat toward Rue du Clos Renard",
      },
      {
        timestamp: 1730214686000,
        instructions: "Turn left onto Rue du Clos Renard",
      },
      {
        timestamp: 1730214715000,
        instructions: "Turn right onto Rue du Maréchal Leclerc/D11",
      },
      {
        timestamp: 1730214765000,
        instructions:
          "Turn left onto Rue Bonne Dame/D960 (signs for Centre Ville)",
      },
      {
        timestamp: 1730214825000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730214834000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730214856000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/cbaf33c0-5ebb-49b5-99bd-f966f0de7dfd-heyrank",
    steps: [
      {
        timestamp: 1730213918000,
        instructions:
          "Head southeast on Allée de l'Auvernat toward Rue du Clos Renard",
      },
      {
        timestamp: 1730213930000,
        instructions: "Turn left onto Rue du Clos Renard",
      },
      {
        timestamp: 1730213959000,
        instructions: "Turn right onto Rue du Maréchal Leclerc/D11",
      },
      {
        timestamp: 1730214009000,
        instructions:
          "Turn left onto Rue Bonne Dame/D960 (signs for Centre Ville)",
      },
      {
        timestamp: 1730214069000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730214078000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730214100000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/f1760aa5-cbc5-49af-96c8-b3bf41d6d8fe-heyrank",
    steps: [
      {
        timestamp: 1730213558000,
        instructions:
          "Head southeast on Allée de l'Auvernat toward Rue du Clos Renard",
      },
      {
        timestamp: 1730213570000,
        instructions: "Turn left onto Rue du Clos Renard",
      },
      {
        timestamp: 1730213599000,
        instructions: "Turn right onto Rue du Maréchal Leclerc/D11",
      },
      {
        timestamp: 1730213649000,
        instructions:
          "Turn left onto Rue Bonne Dame/D960 (signs for Centre Ville)",
      },
      {
        timestamp: 1730213709000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730213718000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730213740000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
  {
    image_url:
      "https://constel-hr-frontend.s3.eu-central-1.amazonaws.com/c4be4365-eee6-48ba-9b00-ea7cf1cb0f3d-heyrank",
    steps: [
      {
        timestamp: 1730213090000,
        instructions:
          "Head southeast on Allée de l'Auvernat toward Rue du Clos Renard",
      },
      {
        timestamp: 1730213102000,
        instructions: "Turn left onto Rue du Clos Renard",
      },
      {
        timestamp: 1730213131000,
        instructions: "Turn right onto Rue du Maréchal Leclerc/D11",
      },
      {
        timestamp: 1730213181000,
        instructions:
          "Turn left onto Rue Bonne Dame/D960 (signs for Centre Ville)",
      },
      {
        timestamp: 1730213241000,
        instructions: "Slight right onto Pl. Aristide Briand",
      },
      {
        timestamp: 1730213250000,
        instructions:
          "At the roundabout, take the 1st exit onto Rue de Lattre de Tassigny",
      },
      {
        timestamp: 1730213272000,
        instructions: "Continue onto Grande Rue/D962",
      },
    ],
  },
];
export const competitorsPlaces = [
  {
    name: "Duff Brewery Taproom",
    rating: 4,
    rating_count: "220",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "Paddy's Pub",
    rating: 4.2,
    rating_count: "190",
    categories: ["Bar", "Restaurant"],
    primary_category: "Pub",
  },
  {
    name: "The Blue Moon Bar",
    rating: 4.3,
    rating_count: "200",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "Springfield Brewhouse",
    rating: 4.1,
    rating_count: "160",
    categories: ["Bar", "Pub", "Restaurant"],
    primary_category: "Brewpub",
  },
  {
    name: "The Heritage Pub",
    rating: 4.1,
    rating_count: "180",
    categories: ["Bar", "Restaurant"],
    primary_category: "Pub",
  },
  {
    name: "The Local Pint",
    rating: 4.3,
    rating_count: "190",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "The Urban Bar",
    rating: 4,
    rating_count: "210",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "Barney's Pub",
    rating: 3.8,
    rating_count: "180",
    categories: ["Restaurant", "Bar"],
    primary_category: "Pub",
  },
  {
    name: "Joe's Bar & Grill",
    rating: 4.2,
    rating_count: "150",
    categories: ["Bar", "Grill"],
    primary_category: "Bar & Grill",
  },
  {
    name: "The Lucky Leprechaun",
    rating: 4.3,
    rating_count: "200",
    categories: ["Beer Garden"],
    primary_category: "Bar",
  },
  {
    name: "The Hoppy Place",
    rating: 4.2,
    rating_count: "170",
    categories: ["Beer Garden"],
    primary_category: "Bar",
  },
  {
    name: "The Barrel Room",
    rating: 4.3,
    rating_count: "150",
    categories: ["Bar"],
    primary_category: "Pub",
  },
  {
    name: "The Happy Hour Haven",
    rating: 4.4,
    rating_count: "190",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "O'Hara's Tavern",
    rating: 3.9,
    rating_count: "210",
    categories: ["Bar"],
    primary_category: "Restaurant",
  },
  {
    name: "Brewer's Alley",
    rating: 4.4,
    rating_count: "150",
    categories: ["Restaurant"],
    primary_category: "Pub",
  },
  {
    name: "The Drunken Clam",
    rating: 4.3,
    rating_count: "180",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "The Tipsy Moose",
    rating: 4.3,
    rating_count: "200",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "Springfield Ale House",
    rating: 4.2,
    rating_count: "170",
    categories: [],
    primary_category: "Pub",
  },
  {
    name: "The Silver Stein",
    rating: 4.3,
    rating_count: "180",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "The Sunset Saloon",
    rating: 4,
    rating_count: "170",
    categories: ["American Restaurant"],
    primary_category: "Bar",
  },
  {
    name: "The Green Dragon",
    rating: 4,
    rating_count: "160",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "The Crafty Brewer",
    rating: 4.3,
    rating_count: "180",
    categories: ["Bar", "Pub", "Brewery"],
    primary_category: "Brewpub",
  },
  {
    name: "Angelo's Coal Oven Pizzeria",
    rating: 4.6,
    rating_count: "298",
    categories: ["Bar"],
    primary_category: "Pizza Restaurant",
  },
  {
    name: "The Cozy Corner Pub",
    rating: 5,
    rating_count: "35",
    categories: ["Restaurant", "Bar"],
    primary_category: "Pub",
  },
  {
    name: "The Copper Mug",
    rating: 4,
    rating_count: "220",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "The Golden Tap",
    rating: 4,
    rating_count: "220",
    categories: [],
    primary_category: "Bar",
  },
  {
    name: "Joe G Pizza & Restaurant",
    rating: 4,
    rating_count: "210",
    categories: ["Bar"],
    primary_category: "Pizza Restaurant",
  },
  /*
  "Mangia 57th - Midtown Italian Food & Corporate Catering NYC",
  "Cucina 8 1/2",
  "Felice 56",
  "Serafina Italian Restaurant Time Hotel",
  "Tony's Di Napoli",
  "Pasta Lovers",
  "Il Tinello",
  "San Pietro",
  "Il Gattopardo",
  "Marea",
  "Pazza Notte",
  "Angelo's Coal Oven Pizzeria",*/
];
export const userPlaces = [
  {
    id: "37f584d9-e89f-481f-a90c-c14bbf024f96",
    name: "Moe's tavern",
    rating_count: "257",
    rating: 4.2,
    address: "74 Walnut Street",
    location: {
      latitude: 40.7654454,
      longitude: -73.9805358,
    },
    primary_category: "Bar",
  },
  {
    id: "37f584d9-e89f-481f-a90c-c14bb7y24f96",
    name: "The Red Door Pub",
    rating: 4,
    rating_count: "190",
    primary_category: "Pub",
    address: "33 Acorn Street",
    location: {
      latitude: 40.7654454,
      longitude: -73.9805358,
    },
  },
];
export const gridPoints = [
  {
    latitude: 40.76949222400905,
    longitude: -73.98587908762754,
    rank: 8,
  },
  {
    latitude: 40.76949221031625,
    longitude: -73.9840978832838,
    rank: 8,
  },
  {
    latitude: 40.76949219662346,
    longitude: -73.98231667894042,
    rank: 5,
  },
  {
    latitude: 40.76949218293066,
    longitude: -73.9805354745974,
    rank: 2,
  },
  {
    latitude: 40.769492169237864,
    longitude: -73.97875427025475,
    rank: 1,
  },
  {
    latitude: 40.769492155545066,
    longitude: -73.97697306591247,
    rank: 1,
  },
  {
    latitude: 40.76949214185227,
    longitude: -73.97519186157055,
    rank: 2,
  },
  {
    latitude: 40.768142666526174,
    longitude: -73.98587887061524,
    rank: 9,
  },
  {
    latitude: 40.76814265283404,
    longitude: -73.98409770244578,
    rank: 8,
  },
  {
    latitude: 40.768142639141885,
    longitude: -73.98231653427668,
    rank: 4,
  },
  {
    latitude: 40.76814262544974,
    longitude: -73.98053536610794,
    rank: 3,
  },
  {
    latitude: 40.76814261175759,
    longitude: -73.97875419793958,
    rank: 1,
  },
  {
    latitude: 40.76814259806544,
    longitude: -73.97697302977159,
    rank: 2,
  },
  {
    latitude: 40.76814258437329,
    longitude: -73.97519186160396,
    rank: 2,
  },
  {
    latitude: 40.76679310907065,
    longitude: -73.98587865361766,
    rank: 11,
  },
  {
    latitude: 40.76679309537916,
    longitude: -73.98409752162003,
    rank: 7,
  },
  {
    latitude: 40.766793081687666,
    longitude: -73.98231638962277,
    rank: 5,
  },
  {
    latitude: 40.766793067996176,
    longitude: -73.98053525762586,
    rank: 1,
  },
  {
    latitude: 40.766793054304685,
    longitude: -73.97875412562932,
    rank: 1,
  },
  {
    latitude: 40.766793040613194,
    longitude: -73.97697299363315,
    rank: 2,
  },
  {
    latitude: 40.766793026921704,
    longitude: -73.97519186163736,
    rank: 4,
  },
  {
    latitude: 40.765443551642534,
    longitude: -73.98587843663483,
    rank: 12,
  },
  {
    latitude: 40.765443537951704,
    longitude: -73.98409734080657,
    rank: 8,
  },
  {
    latitude: 40.76544352426087,
    longitude: -73.98231624497868,
    rank: 5,
  },
  {
    latitude: 40.7654454,
    longitude: -73.9805358,
    rank: 1,
  },
  {
    latitude: 40.765445386309146,
    longitude: -73.9787547041222,
    rank: 1,
  },
  {
    latitude: 40.7654453726183,
    longitude: -73.97697360824476,
    rank: 6,
  },
  {
    latitude: 40.76544535892746,
    longitude: -73.9751925123677,
    rank: 9,
  },
  {
    latitude: 40.76409588367172,
    longitude: -73.98587887066748,
    rank: 20,
  },
  {
    latitude: 40.76409586998154,
    longitude: -73.9840978109555,
    rank: 8,
  },
  {
    latitude: 40.764095856291355,
    longitude: -73.98231675124389,
    rank: 5,
  },
  {
    latitude: 40.764095842601165,
    longitude: -73.98053569153264,
    rank: 1,
  },
  {
    latitude: 40.764095828910975,
    longitude: -73.97875463182176,
    rank: 2,
  },
  {
    latitude: 40.764095815220784,
    longitude: -73.97697357211123,
    rank: 11,
  },
  {
    latitude: 40.7640958015306,
    longitude: -73.97519251240108,
    rank: 12,
  },
  {
    latitude: 40.76274632629837,
    longitude: -73.9858786537141,
    rank: null,
  },
  {
    latitude: 40.76274631260883,
    longitude: -73.98409763016657,
    rank: 12,
  },
  {
    latitude: 40.7627462989193,
    longitude: -73.98231660661942,
    rank: 6,
  },
  {
    latitude: 40.76274628522977,
    longitude: -73.98053558307264,
    rank: 5,
  },
  {
    latitude: 40.76274627154024,
    longitude: -73.97875455952621,
    rank: 7,
  },
  {
    latitude: 40.76274625785071,
    longitude: -73.97697353598015,
    rank: 10,
  },
  {
    latitude: 40.76274624416117,
    longitude: -73.97519251243446,
    rank: 16,
  },
  {
    latitude: 40.761396768952395,
    longitude: -73.98587843677547,
    rank: null,
  },
  {
    latitude: 40.76139675526351,
    longitude: -73.98409744938995,
    rank: null,
  },
  {
    latitude: 40.76139674157463,
    longitude: -73.9823164620048,
    rank: 7,
  },
  {
    latitude: 40.761396727885746,
    longitude: -73.98053547462003,
    rank: 7,
  },
  {
    latitude: 40.76139671419686,
    longitude: -73.97875448723559,
    rank: 11,
  },
  {
    latitude: 40.76139670050798,
    longitude: -73.97697349985154,
    rank: 13,
  },
  {
    latitude: 40.76139668681909,
    longitude: -73.97519251246786,
    rank: 18,
  },
];
