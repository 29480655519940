import { Heading, HeadingProps } from "react-aria-components";

export function DialogHeading({
  children,
  ...rest
}: Omit<HeadingProps, "level" | "slot">) {
  return (
    <Heading level={3} slot="title" {...rest}>
      {children}
    </Heading>
  );
}
