import { get } from "@/src/common/helpers/HTTP.ts";

export async function getAuthUrl() {
  const host = `${window.location.protocol}//${window.location.host}`;

  const loginUrl = `v1/auth/login/redirect?redirect_url=${encodeURIComponent(host)}`;

  const { redirect_url: redirectUrl } = await get(loginUrl);
  return redirectUrl;
}
export async function logout() {
  await get("v1/auth/logout");
  localStorage.removeItem("auth");
  window.location.reload();
}
