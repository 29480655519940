import { NavigateOptions, useNavigate } from "@tanstack/react-router";
import { DetailedPlace } from "common/types.ts";
import { getQueryKeys } from "@/src/common/helpers/getQueryKeys.ts";
import { useQueryClient } from "@tanstack/react-query";

export function usePlaceSelection() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  function selectPlace(placeId: string) {
    const navigateOptions: NavigateOptions = {
      to: "/$placeId/home",
      params: { placeId },
    };
    const existingPlace = queryClient.getQueryData<DetailedPlace>(
      getQueryKeys().places.details(placeId),
    );
    if (existingPlace) {
      const activeKeyword = existingPlace.keywords.find(
        ({ grid_state }) => grid_state === "failed" || grid_state === "ready",
      );
      if (activeKeyword) {
        navigateOptions.search = {
          selectedKeywordId: activeKeyword.id,
        };
      }
    }
    navigate(navigateOptions);
  }
  return { selectPlace };
}
