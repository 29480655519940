import { useQuery } from "@tanstack/react-query";
import { BasePlace, Coordinates } from "common/types.ts";
import { get } from "common/helpers/HTTP.ts";
async function getPlacesRequest(
  searchText: string,
  location: Coordinates | undefined,
): Promise<BasePlace[]> {
  const searchParams = new URLSearchParams();
  searchParams.set("search_text", searchText);

  if (location) {
    searchParams.set("longitude", location.longitude.toString());
    searchParams.set("latitude", location.latitude.toString());
  }

  const { places } = await get(`v1/search/places?${searchParams.toString()}`);
  return places;
}
export default function usePlacesSearch(
  searchText: string,
  location: Coordinates | undefined,
) {
  const { data: places, isLoading: isLoadingPlaces } = useQuery({
    queryKey: ["places", searchText, location],
    queryFn: () => getPlacesRequest(searchText, location),
    enabled: !!searchText,
    placeholderData: (prev) => prev,
  });
  return { places, isLoadingPlaces };
}
