import { Place } from "common/types.ts";
import {
  Badge,
  buttonStyles,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@/src/common/components";
import { PlaceAvatar } from "common/components/PlaceAvatar";
import { Table } from "react-aria-components";

import Star from "fontawesome/solid/star.svg?react";
import ArrowRight from "fontawesome/regular/arrow-right.svg?react";

export default function ProfilesTable({
  places,
  goToPlace,
}: {
  places: Place[];
  goToPlace: (placeId: string) => void;
}) {
  return (
    <Table
      aria-label="gmb profiles"
      onRowAction={(placeId) => goToPlace(placeId as string)}
      className="max-lg:hidden"
    >
      <TableHeader>
        <TableColumn isRowHeader className="w-3/6">
          Profiles
        </TableColumn>
        <TableColumn>Plan</TableColumn>
        <TableColumn>Rate</TableColumn>
        <TableColumn className="w-1/12"></TableColumn>
      </TableHeader>
      <TableBody>
        {places.map(({ id, name, address, rating }) => (
          <TableRow className="group h-17" id={id} key={id}>
            <TableCell>
              <div className="flex items-center gap-x-3">
                <PlaceAvatar placeName={name} size="lg" />
                <div className="flex flex-col gap-x-3">
                  <span className="text-base font-medium">{name}</span>
                  <span className="text-xs text-grey-500">{address}</span>
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="flex">
                <Badge size="sm" variant="greenFull">
                  Demo
                </Badge>
              </div>
            </TableCell>
            <TableCell>
              <div className="flex items-center gap-x-2 text-base font-semibold">
                {rating}
                <Star className="size-4 fill-yellow-300" />
              </div>
            </TableCell>
            <TableCell>
              <div
                className={buttonStyles({
                  variant: "transparent",
                  isIconOnly: true,
                  size: "lg",
                  className:
                    "ml-auto group-data-[hovered]:bg-grey-900 [&>svg]:group-data-[hovered]:fill-white",
                })}
              >
                <ArrowRight />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
