import { useMutation, useQueryClient } from "@tanstack/react-query";
import { post } from "@/src/common/helpers/HTTP.ts";
import { Place } from "common/types.ts";
import { getQueryKeys } from "common/helpers/getQueryKeys.ts";

async function addPlaceRequest(
  placeId: string,
  placeName: string,
  customerId: string,
): Promise<Place> {
  const { place } = await post(`v1/places`, {
    place_id: placeId,
    place_name: placeName,
    customer_id: customerId,
  });
  return place;
}
export default function usePlaceMutations() {
  const queryClient = useQueryClient();

  const { mutateAsync: addPlace, isPending: isAddingPlace } = useMutation({
    mutationFn: ({
      placeId,
      placeName,
      customerId,
    }: {
      placeId: string;
      placeName: string;
      customerId: string;
    }) => addPlaceRequest(placeId, placeName, customerId),
    onSuccess: (newPlace) => {
      queryClient.setQueryData<Place[]>(
        getQueryKeys().places.list(),
        (prevPlaces) => {
          if (prevPlaces) {
            return [...prevPlaces, newPlace];
          } else {
            return [newPlace];
          }
        },
      );
    },
  });
  return {
    addPlace,
    isAddingPlace,
  };
}
