import { useRef, useState } from "react";
import { BasePlace } from "common/types.ts";
import { MobileSheet, Modal, DialogHeading } from "@/src/common/components";
import PlaceSelection from "@/src/common/components/AddBusinessModal/PlaceSelection.tsx";

import AddAccountGraphic from "@/src/assets/add-account.svg?react";
import { useAppStore, useTwBreakpoint } from "common/hooks";

export default function AddBusinessModal() {
  const isDesktop = useTwBreakpoint("lg");
  const { isAddModalOpen, setIsAddModalOpen } = useAppStore();
  const resetStateRef = useRef(false);
  const [selectedPlace, setSelectedPlace] = useState<BasePlace | null>(null);

  function resetState() {
    if (resetStateRef.current) {
      setSelectedPlace(null);
      resetStateRef.current = false;
    }
  }
  function renderContent({ close }: { close: () => void }) {
    return (
      <>
        <AddAccountGraphic className="mx-auto mb-4 size-48 lg:my-12 lg:size-60" />
        <DialogHeading className="mb-2 text-center text-2xl font-semibold">
          Find your Google Business Profile
        </DialogHeading>
        <p className="mb-4 text-center text-sm text-grey-500 lg:text-base">
          Simply find your Google Business Profile to access personalized
          analytics, track your local rankings, and optimize your visibility on
          Google Maps and Local Pack.
        </p>
        <PlaceSelection
          closeDialog={close}
          selectedPlace={selectedPlace}
          setSelectedPlace={setSelectedPlace}
        />
      </>
    );
  }
  if (isDesktop) {
    return (
      <Modal
        variant="white"
        onAfterClose={resetState}
        className="flex-col lg:w-[510px]"
        isOpen={isAddModalOpen}
        setIsOpen={setIsAddModalOpen}
      >
        {renderContent}
      </Modal>
    );
  }
  return (
    <MobileSheet isOpen={isAddModalOpen} setIsOpen={setIsAddModalOpen}>
      {renderContent}
    </MobileSheet>
  );
}
