import { FunctionComponent } from "react";
import { badgeVariants } from "@/src/common/components";
import SliderIcon from "fontawesome/regular/sliders-simple.svg?react";
import HouseIcon from "fontawesome/regular/house-blank.svg?react";
import CarsIcon from "fontawesome/regular/cars.svg?react";
import Visa from "fontawesome/brands/cc-visa.svg?react";
import Mastercard from "fontawesome/brands/cc-mastercard.svg?react";
import AmericanExpress from "fontawesome/brands/cc-amex.svg?react";
import Discover from "fontawesome/brands/cc-discover.svg?react";
import JCB from "fontawesome/brands/cc-jcb.svg?react";
import DinersClub from "fontawesome/brands/cc-diners-club.svg?react";
import CreditCard from "fontawesome/solid/credit-card.svg?react";
import { CardBrand } from "common/types.ts";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../tailwind.config.ts";

//https://evomark.co.uk/development/making-your-tailwind-config-available-to-your-entire-application-with-vite/
export const tailwindTheme = resolveConfig(tailwindConfig).theme;

export const RANKS: Record<
  "high" | "med" | "low",
  {
    colorClassNames: { bg: string; bgHover: string };
    badgeVariant: badgeVariants;
    label: string;
    detailedLabel: string;
  }
> = {
  high: {
    colorClassNames: {
      bg: "bg-green-500",
      bgHover: "hover:bg-green-400",
    },
    badgeVariant: "green",
    label: "High-ranking",
    detailedLabel: "High-ranking: 1-5",
  },
  med: {
    colorClassNames: {
      bg: "bg-yellow-300",
      bgHover: "hover:bg-yellow-200",
    },
    badgeVariant: "yellow",
    label: "Med-ranking",
    detailedLabel: "Med-ranking: 5-10",
  },
  low: {
    colorClassNames: {
      bg: "bg-red-400",
      bgHover: "hover:bg-red-300",
    },
    badgeVariant: "red",
    label: "Low-ranking",
    detailedLabel: "Low-ranking: 10-∞",
  },
};
export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const WEEK_DAYS = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const routes = [
  {
    label: "home",
    icon: <HouseIcon />,
  },
  {
    label: "drives",
    icon: <CarsIcon />,
  },
  {
    label: "settings",
    icon: <SliderIcon />,
  },
];
export const CARD_ICONS: Record<CardBrand, FunctionComponent> = {
  visa: Visa,
  mastercard: Mastercard,
  american_express: AmericanExpress,
  discover: Discover,
  jcb: JCB,
  diners_club: DinersClub,
  other: CreditCard,
};
