import { createRootRoute, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { jwtDecode } from "jwt-decode";
import { getAuthUrl } from "@/src/common/helpers/auth.ts";
import { useEffect } from "react";
import { useAppStore } from "common/hooks";
import { IntercomProvider } from "react-use-intercom";
import { placesQueryOptions } from "@/src/common/datahooks/usePlaces.ts";
import { redirect } from "@tanstack/react-router";
import AddBusinessModal from "common/components/AddBusinessModal/AddBusinessModal.tsx";

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const { hash } = window.location;
if (hash) {
  const token = hash.substring(1);

  if (token !== "") {
    localStorage.setItem("auth", token);
  }
}
export const Route = createRootRoute({
  beforeLoad: async () => {
    const token = localStorage.getItem("auth");
    let isAuthenticated = false;

    if (token) {
      const { exp } = jwtDecode(token);
      const expirationTime = (exp as number) * 1000 - 60000;

      if (Date.now() < expirationTime) {
        isAuthenticated = true;
      }
    }
    if (!isAuthenticated) {
      window.location.href = await getAuthUrl();
    }
  },
  loader: async ({ params, location }) => {
    const places = await queryClient.ensureQueryData(placesQueryOptions);
    let redirectToPlaceId;

    if ("placeId" in params) {
      /* empty */
    } else if (location.pathname === "/") {
      if (places) {
        redirectToPlaceId = places[0].id;
      }
    }

    document.getElementById("loader-container")?.remove();

    if (redirectToPlaceId) {
      throw redirect({
        to: "/$placeId/home",
        replace: true,
        params: { placeId: redirectToPlaceId },
        search: (search) => search,
      });
    }
  },
  component: Root,
});
function Root() {
  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      useAppStore.getState().setGeoState(result.state);
    });
  }, []);
  return (
    <>
      <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID} autoBoot>
        <QueryClientProvider client={queryClient}>
          <Outlet />
          <ReactQueryDevtools />
          <AddBusinessModal />
        </QueryClientProvider>
      </IntercomProvider>
      {process.env.NODE_ENV !== "production" && <TanStackRouterDevtools />}
    </>
  );
}
