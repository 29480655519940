import { cloneElement, JSX } from "react";
import {
  Button,
  ButtonProps,
  Input as AriaInput,
  InputProps as AriaInputProps,
} from "react-aria-components";
import { tv, VariantProps } from "tailwind-variants";

const containerStyle = tv({
  base: [
    "group relative flex items-center [&_svg]:absolute [&_svg]:fill-grey-700",
    "[&>svg]:has-[:placeholder-shown]:fill-grey-500",
    "[&>svg]:has-[input[data-disabled]]:fill-grey-400",
    "[&>svg]:has-[input[data-focused]]:fill-grey-900",
  ],
  variants: {
    size: {
      lg: "[&_svg]:size-4 [&>svg]:left-3.5",
      md: "[&_svg]:size-3.5 [&>svg]:left-3",
      sm: "[&_svg]:size-3 [&>svg]:left-2.5",
    },
  },
});
const inputStyle = tv({
  base: [
    "flex bg-transparent transition-[background] rounded-full border font-medium border-grey-300 text-grey-700 placeholder:text-grey-400",
    "data-[disabled]:text-grey-400 data-[disabled]:text-grey-400 data-[disabled]:bg-grey-200 data-[disabled]:pointer-events-none",
    "data-[focused]:outline data-[focused]:outline-3 data-[focused]:outline-purple-100 data-[focused]:text-grey-900 data-[focused]:bg-white data-[focused]:border-purple-400",
    "data-[hovered]:bg-grey-100 data-[hovered]:border-grey-100",
    "data-[hovered]:data-[focused]:border-purple-400 data-[hovered]:data-[focused]:bg-white",
  ],
  variants: {
    size: {
      lg: "h-12 px-6 text-base",
      md: "h-11 px-5 text-sm",
      sm: "h-8 px-4 text-xs",
    },
    hasLeftIcon: { true: "", false: "" },
    hasRightButton: { true: "", false: "" },
  },
  compoundVariants: [
    {
      size: "sm",
      hasLeftIcon: true,
      class: "pl-7",
    },
    {
      size: "md",
      hasLeftIcon: true,
      class: "pl-8.5",
    },
    {
      size: "lg",
      hasLeftIcon: true,
      class: "pl-10",
    },
    {
      size: "sm",
      hasRightButton: true,
      class: "pr-8.5",
    },
    {
      size: "md",
      hasRightButton: true,
      class: "pr-12",
    },
    {
      size: "lg",
      hasRightButton: true,
      class: "pr-14",
    },
  ],
});
export interface InputProps
  extends VariantProps<typeof inputStyle>,
    Omit<AriaInputProps, "size"> {
  leftIcon?: JSX.Element;
  rightButton?: JSX.Element;
}
export function Input({
  size,
  leftIcon,
  className,
  rightButton,
  ...rest
}: InputProps) {
  return (
    <div className={containerStyle({ size })}>
      {leftIcon}
      <AriaInput
        className={
          typeof className === "function"
            ? (renderProps) =>
                inputStyle({
                  size,
                  hasLeftIcon: !!leftIcon,
                  hasRightButton: !!rightButton,
                  className: className(renderProps),
                })
            : inputStyle({
                size,
                hasLeftIcon: !!leftIcon,
                hasRightButton: !!rightButton,
                className,
              })
        }
        {...rest}
      />
      {rightButton && cloneElement(rightButton, { size })}
    </div>
  );
}

const inputButtonStyle = tv({
  base: "bg-grey-50 transition-[background] flex items-center justify-center rounded-full absolute right-0.5 data-[hovered]:bg-grey-100 group-has-[input[data-disabled]]:bg-grey-200 [&>svg]:group-has-[input[data-disabled]]:fill-grey-400",
  variants: {
    size: {
      sm: "size-7",
      md: "size-10",
      lg: "size-11",
    },
  },
});
export interface InputButtonProps
  extends VariantProps<typeof inputStyle>,
    ButtonProps {}
export function InputButton({
  children,
  size,
  className,
  ...rest
}: InputButtonProps) {
  return (
    <Button
      className={
        typeof className === "function"
          ? (renderProps) =>
              inputButtonStyle({ size, className: className(renderProps) })
          : inputButtonStyle({ size, className })
      }
      {...rest}
    >
      {children}
    </Button>
  );
}
