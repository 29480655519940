import type { Config } from "tailwindcss";
import tailwindCssAnimate from "tailwindcss-animate";
import tailwindScrollbar from "tailwind-scrollbar";

const config = {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    colors: {
      transparent: "transparent",
      white: "#FFFFFF",
      grey: {
        50: "#F5F7FA",
        100: "#EEF1F7",
        200: "#E2E8F2",
        300: "#CED8E9",
        400: "#96A6C0",
        500: "#707C91",
        600: "#4D5666",
        700: "#333B47",
        800: "#1A2029",
        900: "#000000",
      },
      green: {
        50: "#E2FEE2",
        100: "#C5FCC5",
        200: "#A7FBA8",
        300: "#91F396",
        400: "#75F07D",
        500: "#5BE769",
        600: "#3DD64D",
        700: "#32AE43",
        800: "#2B823A",
        900: "#22592C",
      },
      purple: {
        50: "#E3E0FF",
        100: "#D7D3FD",
        200: "#C2B9FE",
        300: "#B1A1FD",
        400: "#9675FA",
        500: "#8645F9",
        600: "#7E3AF2",
        700: "#6C2BD9",
        800: "#5221B5",
        900: "#3F1D96",
      },
      red: {
        50: "#FDE7E7",
        100: "#FFD6D6",
        200: "#FFC2C2",
        300: "#FF9E9F",
        400: "#FF5757",
        500: "#FE2C2A",
        600: "#E51515",
        700: "#CC1311",
        800: "#9F1413",
        900: "#7C1716",
      },
      yellow: {
        50: "#FEFEDE",
        100: "#FEF7A7",
        200: "#FFE84C",
        300: "#FCCA00",
        400: "#E4A000",
        500: "#C47800",
        600: "#A15800",
        700: "#8F4B03",
        800: "#743A09",
        900: "#652F0B",
      },
      bad: {
        10: "",
      },
    },
    zIndex: {
      base: "10",
      popover: "20",
      modal: "30",
      auto: "auto",
    },
    boxShadow: {
      sm: "0px 1px 6px -1px rgba(17, 24, 39, 0.06)",
      md: "0px 2px 6px 2px rgba(17, 24, 39, 0.14)",
      lg: "0px 4px 15px 0px rgba(17, 24, 39, 0.14)",
      xl: "0px 6px 25px 0px rgba(17, 24, 39, 0.14)",
      "2xl": "0px 8px 50px 0px rgba(17, 24, 39, 0.25)",
      none: "none",
    },
    extend: {
      transitionDuration: {
        DEFAULT: "300ms",
      },
      fontSize: {
        "2xs": ["0.625rem", { lineHeight: "0.875rem" }],
        xs: ["0.75rem", { lineHeight: "1rem" }],
        "3xl": ["1.875rem", { lineHeight: "2.5rem" }],
        "4xl": ["2.25rem", { lineHeight: "2.75rem" }],
        "5xl": ["3rem", { lineHeight: "3.5rem" }],
        "6xl": ["3.75rem", { lineHeight: "4rem" }],
        "7xl": ["4.5rem", { lineHeight: "5rem" }],
      },
      borderRadius: {
        "4xl": "1.75rem",
        "5xl": "2rem",
        "6xl": "2.5rem",
        "7xl": "3rem",
        "8xl": "3.5rem",
        "9xl": "4rem",
        "10xl": "5rem",
      },
      spacing: {
        4.5: "1.125rem",
        5.5: "1.375rem",
        7.5: "1.875rem",
        8.5: "2.124rem",
        17: "4.25rem",
      },
      outlineWidth: {
        3: "3px",
      },
    },
  },
  plugins: [tailwindCssAnimate, tailwindScrollbar],
} satisfies Config;
export default config;
