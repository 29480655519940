import { Button } from "../Button";
import { clsx } from "clsx";

import AngleRightIcon from "fontawesome/solid/angle-right.svg?react";
import AngleLeftIcon from "fontawesome/solid/angle-left.svg?react";

const maxItems = 2;
export function Pagination({
  pagesCount,
  pageIndex,
  setPageIndex,
  className,
  size,
}: {
  pagesCount: number;
  pageIndex: number;
  setPageIndex: (pageIndex: number) => void;
  className?: string;
  size: "sm" | "md";
}) {
  const pageComponents = [];
  for (let i = 0; i < pagesCount; i += 1) {
    const isSelected = pageIndex === i;
    if (
      pageIndex === i ||
      (pageIndex <= i + maxItems && pageIndex >= i - maxItems)
    ) {
      pageComponents.push(
        <Button
          key={i}
          isIconOnly
          variant={isSelected ? "black" : "white"}
          size={size}
          onPress={() => setPageIndex(i)}
        >
          {i + 1}
        </Button>,
      );
    }
  }

  return (
    <div className={clsx("flex rounded-full bg-grey-50 p-0.5", className)}>
      <Button
        isDisabled={pageIndex <= 0}
        onPress={() => setPageIndex(pageIndex - 1)}
        isIconOnly
        variant="white"
        size={size}
      >
        <AngleLeftIcon />
      </Button>
      {pageComponents}
      <Button
        isDisabled={pageIndex >= pagesCount - 1}
        onPress={() => setPageIndex(pageIndex + 1)}
        isIconOnly
        variant="white"
        size={size}
      >
        <AngleRightIcon />
      </Button>
    </div>
  );
}
