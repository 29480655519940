import { useInfiniteQuery } from "@tanstack/react-query";
import { Keyword, Snapshot } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";
import { getDummyPlace, getSnapshots } from "@/src/dummyGenerator.ts";

type RequestParams = { page: number; pageSize: number };
async function getKeywordSnapshots(
  placeId: string,
  keywordId: string,
  { page, pageSize }: RequestParams,
): Promise<{ snapshots: Snapshot[]; hasMoreData: boolean }> {
  if (getDummyPlace(placeId)) {
    const dummySnapshots = getSnapshots(placeId, keywordId);
    const pageCount = Math.ceil(dummySnapshots.length / pageSize);
    const hasMoreData = page + 1 < pageCount;
    return {
      snapshots: dummySnapshots.slice(
        page * pageSize,
        page * pageSize + pageSize,
      ),
      hasMoreData,
    };
  }
  const searchParams = new URLSearchParams();
  searchParams.set("search_text", page.toString());
  searchParams.set("search_text", pageSize.toString());

  const { snapshots, has_more_data } = await get(
    `v1/places/${placeId}/keywords/${keywordId}/snapshots?${searchParams.toString()}`,
  );
  return { snapshots, hasMoreData: has_more_data };
}
export default function useKeywordSnapshots(
  placeId: string,
  keyword: Keyword | undefined,
  pageSize: number,
) {
  const keywordId = keyword ? keyword.id : "";
  const {
    data,
    isLoading: isLoadingKeywordSnapshots,
    error: keywordSnapshotsError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["keywordSnapshots", placeId, keywordId],
    queryFn: ({ pageParam }) =>
      getKeywordSnapshots(placeId, keywordId, { page: pageParam, pageSize }),
    enabled: !!keyword,
    initialPageParam: 0,
    getNextPageParam: (lastPage, _allPages, lastPageParam) => {
      if (lastPage.hasMoreData) {
        return lastPageParam + 1;
      }
    },
  });

  return {
    keywordSnapshots: data
      ? data.pages.map(({ snapshots }) => snapshots).flat()
      : undefined,
    fetchNextPage,
    hasNextPage,
    isLoadingKeywordSnapshots,
    keywordSnapshotsError,
  };
}
