import { createFileRoute } from "@tanstack/react-router";

import FullName from "@/src/pages/account/FullName.tsx";
import Email from "@/src/pages/account/Email.tsx";
import Password from "@/src/pages/account/Password.tsx";
import Units from "@/src/pages/account/Units.tsx";
import ArrowRightFromBracket from "fontawesome/regular/arrow-right-from-bracket.svg?react";
import { Button } from "@/src/common/components";
import { useState } from "react";

export const Route = createFileRoute("/_layout/account")({
  component: Account,
});
function Account() {
  const [editingItem, setEditingItem] = useState<string>();

  function toggleEditing(itemKey: string) {
    if (editingItem === itemKey) {
      setEditingItem(undefined);
    } else {
      setEditingItem(itemKey);
    }
  }

  return (
    <div className="flex grow flex-col gap-x-4 gap-y-3 rounded-4xl bg-white p-3 lg:flex-row lg:rounded-7xl lg:p-6 lg:pb-8">
      <div className="flex flex-col gap-y-1 lg:basis-1/2">
        <h1 className="text-lg font-semibold lg:text-xl">Account settings</h1>
        <p className="text-sm font-medium text-grey-500">
          Manage your account here
        </p>
      </div>
      <div className="flex flex-col lg:basis-1/2">
        <FullName
          isEditing={editingItem === "fullName"}
          toggleIsEditing={() => toggleEditing("fullName")}
        />
        <Email
          isEditing={editingItem === "email"}
          toggleIsEditing={() => toggleEditing("email")}
        />
        <Password
          isEditing={editingItem === "password"}
          toggleIsEditing={() => toggleEditing("password")}
        />
        <Units
          isEditing={editingItem === "units"}
          toggleIsEditing={() => toggleEditing("units")}
        />
      </div>
      <Button size="md" variant="white" className="mt-auto lg:hidden">
        <ArrowRightFromBracket />
        Log out
      </Button>
    </div>
  );
}
