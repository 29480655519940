import { ReactNode } from "react";
import { clsx } from "clsx";
import { getRankColorClassnames } from "common/utils.ts";

export default function MiniGrid({
  gridSize,
  preview,
}: {
  gridSize: number;
  preview: (number | null)[];
}) {
  const gridRows: ReactNode[] = [];
  for (let i = 0; i < gridSize; i += 1) {
    const gridColumns = [];
    for (let j = 0; j < gridSize; j += 1) {
      gridColumns.push(
        <div
          className={clsx(
            "size-4 rounded-full",
            getRankColorClassnames(preview[i + j]).bg,
          )}
        />,
      );
    }
    gridRows.push(<div className="flex gap-x-2">{gridColumns}</div>);
  }
  return <div className="flex flex-col items-center gap-y-2">{gridRows}</div>;
}
