import { useLayoutEffect, useState } from "react";
import { tailwindTheme } from "@/src/constants.tsx";

type Breakpoint = keyof typeof tailwindTheme.screens;

export function useTwBreakpoint(breakpoint: Breakpoint) {
  const mediaQuery = `(min-width: ${tailwindTheme.screens[breakpoint]})`;
  const [isMatchingQuery, setIsMatchingQuery] = useState<boolean>(
    window.matchMedia(mediaQuery).matches,
  );

  useLayoutEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    const documentChangeHandler = () =>
      setIsMatchingQuery(mediaQueryList.matches);

    mediaQueryList.addEventListener("change", documentChangeHandler);

    setIsMatchingQuery(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener("change", documentChangeHandler);
    };
  }, [mediaQuery]);

  return isMatchingQuery;
}
