import CircleCheck from "fontawesome/solid/circle-check.svg?react";
import { Button } from "common/components";
import { useTwBreakpoint } from "common/hooks";

const features = [
  "Target Keywords",
  "Up to 15x15 Grid view",
  "Grid Analytics",
  "Daily Drives",
];
export default function NoSubscriptionPlaceholder() {
  const isDesktop = useTwBreakpoint("lg");

  return (
    <div className="flex flex-col justify-between rounded-4xl bg-grey-900 px-4 py-5 lg:flex-row lg:rounded-7xl lg:px-6 lg:py-7  ">
      <div className="flex flex-col max-lg:mb-12">
        <span className="mb-1 text-3xl font-medium text-green-100 lg:text-white">
          Premium subscription
        </span>
        <p className="mb-8 text-base text-grey-300">
          The best way to rank your Google Business Profile quickly.
          <br /> Ideal for brands in a competitive industry
        </p>
        <div className="flex flex-col max-lg:gap-y-3 lg:flex-row lg:gap-x-6">
          {features.map((feature) => (
            <div
              key={feature}
              className="flex items-center gap-x-2 text-base font-medium text-white"
            >
              <CircleCheck className="size-4 fill-green-400 lg:fill-purple-400" />
              {feature}
            </div>
          ))}
        </div>
      </div>
      <div className="flex items-end justify-between lg:flex-col">
        <div className="flex gap-x-1.5">
          <span className="self-end text-xl text-grey-300">from</span>
          <span className="text-3xl font-semibold text-white lg:text-6xl">
            $30
          </span>
          <span className="self-start text-grey-300">/month</span>
        </div>
        <Button variant={isDesktop ? "green" : "white"} size="md">
          Get started
        </Button>
      </div>
    </div>
  );
}
