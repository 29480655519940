import { createFileRoute, useNavigate } from "@tanstack/react-router";
import {
  Badge,
  Button,
  Calendar,
  Popover,
  SelectButton,
  MobileSheet,
} from "@/src/common/components";

import Cars from "fontawesome/solid/cars.svg?react";
import CompactDisc from "fontawesome/solid/compact-disc.svg?react";
import Clock from "fontawesome/solid/clock.svg?react";
import Sliders from "fontawesome/solid/sliders.svg?react";
import AllDrives from "@/src/pages/drives/components/AllDrives.tsx";
import { Dialog, DialogTrigger } from "react-aria-components";
import GridAndDrivesSettings from "@/src/common/components/GridAndDrivesSettings/GridAndDrivesSettings.tsx";
import {
  getLocalTimeZone,
  parseDate,
  today,
  isToday,
} from "@internationalized/date";
import { useTwBreakpoint } from "common/hooks";
import { formatDayAndMonth } from "common/utils.ts";

type SearchParams = {
  date: string;
};
export const Route = createFileRoute("/$placeId/_layout/drives")({
  component: Drives,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      date: search.date
        ? (search.date as string)
        : today(getLocalTimeZone()).toString(),
    };
  },
  staticData: {
    pageTitle: "Dashboard",
  },
});
function Drives() {
  const isDesktop = useTwBreakpoint("lg");
  const isDesktopOrTablet = useTwBreakpoint("md");
  const navigate = useNavigate({ from: "/$placeId/drives" });
  const { date } = Route.useSearch();

  const calendarDate = parseDate(date);

  const titleComponent = (
    <h3 className="text-lg font-semibold lg:text-xl">Recent drives</h3>
  );
  const badgesComponent = (
    <div className="flex flex-wrap gap-1">
      <Badge variant="grey" size="md">
        <Cars />
        Daily drives: 100
      </Badge>
      <Badge variant="grey" size="md">
        <CompactDisc />
        Radius: 20km
      </Badge>
      <Badge variant="grey" size="md">
        <Clock />
        Drives working hours: 24/7
      </Badge>
    </div>
  );
  const manageDrivesComponent = (
    <GridAndDrivesSettings
      initialTab="drives"
      renderTriggerComponent={(onPress) => (
        <Button
          variant="black"
          size="lg"
          onPress={onPress}
          isIconOnly={!isDesktop}
        >
          {isDesktop && "Manage drives"}
          <Sliders />
        </Button>
      )}
    />
  );
  const DateSelectComponent = isDesktopOrTablet ? Popover : MobileSheet;
  const dateSelectComponent = (
    <DialogTrigger>
      <SelectButton
        size="lg"
        placeholder="Filter date"
        onClear={
          isToday(calendarDate, getLocalTimeZone())
            ? undefined
            : () =>
                navigate({
                  search: (prevSearch) => ({
                    ...prevSearch,
                    date: today(getLocalTimeZone()).toString(),
                  }),
                })
        }
        className="max-lg:w-full"
      >
        {formatDayAndMonth(calendarDate)}
      </SelectButton>
      <DateSelectComponent className="md:p-4">
        <Dialog>
          {({ close }) => {
            return (
              <Calendar
                onApply={(date) => {
                  navigate({
                    search: (prevSearch) => ({ ...prevSearch, date }),
                  });
                  close();
                }}
                onCancel={close}
                initialSelectedDate={calendarDate}
              />
            );
          }}
        </Dialog>
      </DateSelectComponent>
    </DialogTrigger>
  );

  return (
    <div className="flex grow flex-col rounded-4xl bg-grey-50 lg:rounded-7xl">
      {isDesktop ? (
        <div className="flex items-center justify-between p-6 pb-5">
          <div className="flex flex-col gap-y-1">
            {titleComponent}
            {badgesComponent}
          </div>
          <div className="flex gap-x-2">
            {dateSelectComponent}
            {manageDrivesComponent}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-y-2 p-3">
          <div className="flex justify-between">
            {titleComponent}
            {manageDrivesComponent}
          </div>
          {badgesComponent}
          {dateSelectComponent}
        </div>
      )}
      <AllDrives />
    </div>
  );
}
