import { JSX } from "react";

import StarHalfIcon from "fontawesome/regular/star-half-stroke.svg?react";
import StarFullIcon from "fontawesome/solid/star.svg?react";
import StarEmptyIcon from "fontawesome/regular/star.svg?react";

const maxRating = 5;
export function RatingStars({ rating }: { rating: number }) {
  const starComponents: JSX.Element[] = [];
  const iconClassName = "size-4 fill-yellow-300";

  for (let i = 0; i < maxRating; i += 1) {
    const currentStar = i + 1;
    if (rating >= currentStar) {
      starComponents.push(<StarFullIcon key={i} className={iconClassName} />);
    } else if (currentStar - rating < 1 && currentStar - rating <= 0.5) {
      starComponents.push(<StarHalfIcon key={i} className={iconClassName} />);
    } else {
      starComponents.push(<StarEmptyIcon key={i} className={iconClassName} />);
    }
  }
  return <div className="flex items-center gap-x-0.5">{starComponents}</div>;
}
