import { Select } from "react-aria-components";
import {
  SelectButton,
  Popover,
  DropdownBox,
  DropdownBoxItem,
  DropdownActionButton,
  Badge,
  PopoverDialog,
} from "@/src/common/components";

import Cog from "fontawesome/regular/gear.svg?react";
import ChevronRight from "fontawesome/regular/chevron-right.svg?react";
import Tag from "fontawesome/solid/tag.svg?react";

import { Keyword as KeywordType } from "common/types.ts";
import { useNavigate } from "@tanstack/react-router";
import KeywordsModal from "@/src/pages/home/KeywordsModal/KeywordsModal.tsx";
import { useState } from "react";
import { useSelectedPlace } from "@/src/contexts.ts";
import { useAppStore } from "common/hooks";
export default function KeywordSelect({
  data,
  selectedKeywordId,
  isDisabled,
}: {
  data:
    | {
        keywords: KeywordType[];
        maxKeywords: number;
      }
    | undefined;
  selectedKeywordId: string | undefined;
  isDisabled: boolean;
}) {
  const { isDemoMode } = useSelectedPlace();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate({ from: "/$placeId/home" });

  const selectedKeyword =
    data && selectedKeywordId
      ? data.keywords.find(({ id }) => id === selectedKeywordId)
      : undefined;

  return (
    <>
      <Select
        aria-label="Select a keyword"
        selectedKey={selectedKeyword ? selectedKeyword.id : null}
        onSelectionChange={(selection) => {
          navigate({
            search: (prevSearch) => ({
              ...prevSearch,
              selectedKeywordId: selection as string,
            }),
          });
        }}
      >
        <SelectButton
          leftIcon={<Tag />}
          placeholder="Select a keyword"
          className="min-w-72 max-lg:w-full"
          size="lg"
          isDisabled={isDisabled}
        >
          {selectedKeyword && selectedKeyword.label}
        </SelectButton>
        <Popover className="max-lg:w-[--trigger-width]">
          <PopoverDialog aria-label="test">
            {({ close }) => (
              <>
                <DropdownActionButton
                  size="lg"
                  onPress={() => {
                    close();
                    if (isDemoMode) {
                      useAppStore.getState().setIsAddModalOpen(true);
                    } else {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  <Cog />
                  Manage keywords
                  <ChevronRight />
                </DropdownActionButton>
                <DropdownBox size="lg" items={data ? data.keywords : []}>
                  {({ id, label, difficulty }) => (
                    <DropdownBoxItem
                      size="lg"
                      leftComponent={<Tag />}
                      rightComponent={
                        <Badge size="sm" variant="grey">
                          {difficulty}
                        </Badge>
                      }
                      title={label}
                      key={id}
                      id={id}
                    />
                  )}
                </DropdownBox>
              </>
            )}
          </PopoverDialog>
        </Popover>
      </Select>
      <KeywordsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        keywords={data ? data.keywords : []}
        maxKeywords={data ? data.maxKeywords : 0}
      />
    </>
  );
}
