import { useQuery } from "@tanstack/react-query";
import { DetailedPlace } from "common/types.ts";
import { get } from "@/src/common/helpers/HTTP.ts";
import { getQueryKeys } from "@/src/common/helpers/getQueryKeys.ts";
import { getDummyPlace } from "@/src/dummyGenerator.ts";

async function getPlaceRequest(placeId: string): Promise<DetailedPlace> {
  const dummyPlace = getDummyPlace(placeId);
  if (dummyPlace) {
    return dummyPlace;
  }
  const { place } = await get(`v1/places/${placeId}`);
  return place;
}
export default function usePlace(placeId: string) {
  const { data: place, isLoading: isLoadingPlace } = useQuery({
    queryKey: getQueryKeys().places.details(placeId),
    queryFn: () => getPlaceRequest(placeId),
    refetchInterval: ({ state }) => {
      if (state.data) {
        const shouldPoll =
          !state.data.keywords.length ||
          state.data.keywords.find(
            ({ grid_state }) =>
              grid_state === "processing" || grid_state === "pending",
          );
        if (shouldPoll) {
          return 3000;
        }
      }
    },
  });

  return { place, isLoadingPlace };
}
